// Account section
.tn-area-nav__list {
    .tn-area-nav__list-item {
        font-weight: $font-weight-bold;
        margin-bottom: 0;
        background-color: $light-grey;

        &:first-child {
            border-radius: $border-radius-large $border-radius-large 0 0;
        }

        &:last-child {
            border-radius: 0 0 $border-radius-large $border-radius-large;
        }

        & + .tn-area-nav__list-item {
            border-top: 2px solid $white;
        }

        &:not(:has(a)) {
            padding: $spacer-s $spacer-m;
            background-color: $black;
            color: $white;
        }

        a {
            @include btn-to-link;
            padding: $spacer-s $spacer-m;
            width: 100%;
            justify-content: space-between;
        }
    }
}

// Continue shopping link
.tn-account-interests-page form,
#tn-update-form {
    .row:last-child a:last-child {
        @include cta;
        @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-bg-hover);
    }
}

.tn-page-layout--two-column .tn-page-layout__main-container {
    border-color: $white !important;
    border-width: 1px;
}

.tn-component-contact-permissions {
    .tn-component-contact-permissions__settings__item {
        border: none;
        border-radius: 0;
        padding: 0;
        overflow: hidden;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        gap: $padding-base-horizontal;

        @include mq($from: tablet) {
            grid-template-columns: 1fr 1fr;
        }

        @include mq($from: desktop) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        img {
            width: 100%;
            height: auto;
            aspect-ratio: 1.6;
        }
    }

    .tn-component-contact-permissions__settings__item__header {
        margin-bottom: 0;
        grid-column: -1 / 1;
    }

    .tn-component__fieldset-radio {
        padding: $spacer-l 0 0 0;
        margin-top: -$spacer-ml;
        border-radius: 0;
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
}


.tn-page-layout.tn-page-layout--two-column {
    @include mq($from: tablet) {
        grid-template-columns: 265px 1fr;
    }

    .tn-page-layout__sidebar-container {
        @include mq($from: tablet) {
            padding-right: $spacer-m;
        }
    }
    .tn-page-layout__main-container {
        border-left: none;
    }
}

// membership section
.tn-account-memberships-page {
    .tn-membership-summary__organization {
        display: none;
    }

    .tn-membership-summary__list {
        .tn-account-section-list__item {
            padding: $spacer-m;
            margin-bottom: $spacer-m;
            border-top: none;
            border-bottom: none;
            background-color: $light-grey;
            border-radius: $border-radius-large;
        }
    }

    .tn-membership-summary__heading {
        margin-bottom: 0;
    }

    // client requires these hidden - presuming as they do not expire

    .tn-membership-summary__expiration-date-label,
    .tn-membership-summary__expiration-date-value {
        display: none;
    }
    // client requires these hidden - presuming as they do not expire
    .tn-membership-summary__status--renewal {
        display: none;
    }

}

// donation section
.tn-giving-history-page {
    .tn-giving-history__list {
        .tn-giving-history__year-list-item {
            padding: $spacer-m;
            margin-bottom: $spacer-m;
            border-top: none;
            border-bottom: none;
            background-color: $light-grey;
            border-radius: $border-radius-large;
        }
    }

    .tn-giving-history__property-label {
        font-weight: $font-weight-bold;
        font-family: $font-family-copy;
        color: $black;
    }

    .tn-giving-history__property-value {
        @include font-size-base-plus-1;
        font-family: $font-family-copy;

    }
}

// events section
.tn-upcoming-events-page {
    .tn-upcoming-event__list {
        .tn-upcoming-event__list-item {
            padding: $spacer-m;
            margin-bottom: $spacer-m;
            border-top: none;
            border-bottom: none;
            background-color: $light-grey;
            border-radius: $border-radius-large;
        }
    }

    .tn-upcoming-event__title {
        margin-bottom: 10px;
    }

    .tn-upcoming-event__date-time {
        @include font-size-base-plus-1;
        font-weight: $font-weight-bold;
        font-family: $font-family-copy;
    }
}

.tn-syos-seat-map__seat, .twc-icon {
// Row A
&[data-tn-seat-id="529399"] { transform: matrix(1, 0, 0, 1, -98.344482421875, 1339.0374755859375); }
&[data-tn-seat-id="529400"] { transform: matrix(1, 0, 0, 1, -206.19287109375, 1286.7244873046875); }
&[data-tn-seat-id="529401"] { transform: matrix(1, 0, 0, 1, -310.4781494140625, 1232.1279296875); }
&[data-tn-seat-id="529402"] { transform: matrix(1, 0, 0, 1, -411.73931884765625, 1175.85107421875); }
&[data-tn-seat-id="529403"] { transform: matrix(1, 0, 0, 1, -510.01861572265625, 1118.1214599609375); }
&[data-tn-seat-id="529404"] { transform: matrix(1, 0, 0, 1, -605.2437133789062, 1059.0970458984375); }
&[data-tn-seat-id="529405"] { transform: matrix(1, 0, 0, 1, -697.2776794433594, 998.9149169921875); }
&[data-tn-seat-id="529406"] { transform: matrix(1, 0, 0, 1, -785.956787109375, 937.71875); }
&[data-tn-seat-id="529407"] { transform: matrix(1, 0, 0, 1, -871.103271484375, 875.6683349609375); }
&[data-tn-seat-id="529408"] { transform: matrix(1, 0, 0, 1, -952.53466796875, 812.9464111328125); }
&[data-tn-seat-id="529409"] { transform: matrix(1, 0, 0, 1, -1030.0829772949219, 749.76318359375); }
&[data-tn-seat-id="529410"] { transform: matrix(1, 0, 0, 1, -1103.6025085449219, 686.3555908203125); }
&[data-tn-seat-id="529411"] { transform: matrix(1, 0, 0, 1, -1172.9890441894531, 622.9841918945312); }
&[data-tn-seat-id="529412"] { transform: matrix(1, 0, 0, 1, -1238.1836242675781, 559.9253540039062); }
&[data-tn-seat-id="529413"] { transform: matrix(1, 0, 0, 1, -1299.1892700195312, 497.46063232421875); }
&[data-tn-seat-id="529414"] { transform: matrix(1, 0, 0, 1, -1356.0682678222656, 435.8642578125); }
&[data-tn-seat-id="529415"] { transform: matrix(1, 0, 0, 1, -1408.9432373046875, 375.39080810546875); }
&[data-tn-seat-id="529416"] { transform: matrix(1, 0, 0, 1, -1457.98974609375, 316.26361083984375); }
&[data-tn-seat-id="529417"] { transform: matrix(1, 0, 0, 1, -1503.422119140625, 258.66864013671875); }
&[data-tn-seat-id="529418"] { transform: matrix(1, 0, 0, 1, -1545.4915771484375, 202.74667358398438); }
&[data-tn-seat-id="529419"] { transform: matrix(1, 0, 0, 1, -1584.4562377929688, 148.59957885742188); }
&[data-tn-seat-id="529420"] { transform: matrix(1, 0, 0, 1, -1620.5869750976562, 96.28567504882812); }
&[data-tn-seat-id="529421"] { transform: matrix(1, 0, 0, 1, -1654.1449584960938, 45.83123779296875); }



// Row B
&[data-tn-seat-id="529323"] { transform: matrix(1, 0, 0, 1, -115.8768310546875, 1436.0875244140625); }
&[data-tn-seat-id="529324"] { transform: matrix(1, 0, 0, 1, -221.63763427734375, 1383.397216796875); }
&[data-tn-seat-id="529325"] { transform: matrix(1, 0, 0, 1, -324.06121826171875, 1328.7008056640625); }
&[data-tn-seat-id="529326"] { transform: matrix(1, 0, 0, 1, -423.7021484375, 1272.545654296875); }
&[data-tn-seat-id="529327"] { transform: matrix(1, 0, 0, 1, -520.6341552734375, 1215.1361083984375); }
&[data-tn-seat-id="529328"] { transform: matrix(1, 0, 0, 1, -614.8261108398438, 1156.61279296875); }
&[data-tn-seat-id="529329"] { transform: matrix(1, 0, 0, 1, -706.1819763183594, 1097.091552734375); }
&[data-tn-seat-id="529330"] { transform: matrix(1, 0, 0, 1, -794.5845031738281, 1036.6903076171875); }
&[data-tn-seat-id="529331"] { transform: matrix(1, 0, 0, 1, -879.895751953125, 975.5335693359375); }
&[data-tn-seat-id="529332"] { transform: matrix(1, 0, 0, 1, -961.9710693359375, 913.76171875); }
&[data-tn-seat-id="529333"] { transform: matrix(1, 0, 0, 1, -1040.6683654785156, 851.53369140625); }
&[data-tn-seat-id="529334"] { transform: matrix(1, 0, 0, 1, -1115.8555603027344, 789.0279541015625); }
&[data-tn-seat-id="529335"] { transform: matrix(1, 0, 0, 1, -1187.4204711914062, 726.4429321289062); }
&[data-tn-seat-id="529336"] { transform: matrix(1, 0, 0, 1, -1255.2805786132812, 663.993896484375); }
&[data-tn-seat-id="529337"] { transform: matrix(1, 0, 0, 1, -1319.3893737792969, 601.9076538085938); }
&[data-tn-seat-id="529338"] { transform: matrix(1, 0, 0, 1, -1379.7417907714844, 540.4151611328125); }
&[data-tn-seat-id="529339"] { transform: matrix(1, 0, 0, 1, -1436.3817138671875, 479.74365234375); }
&[data-tn-seat-id="529340"] { transform: matrix(1, 0, 0, 1, -1489.3958435058594, 420.10687255859375); }
&[data-tn-seat-id="529341"] { transform: matrix(1, 0, 0, 1, -1538.9113159179688, 361.69873046875); }
&[data-tn-seat-id="529342"] { transform: matrix(1, 0, 0, 1, -1585.0953979492188, 304.684326171875); }
&[data-tn-seat-id="529343"] { transform: matrix(1, 0, 0, 1, -1628.1349487304688, 249.19992065429688); }
&[data-tn-seat-id="529344"] { transform: matrix(1, 0, 0, 1, -1668.2447509765625, 195.34542846679688); }
&[data-tn-seat-id="529345"] { transform: matrix(1, 0, 0, 1, -1705.6392822265625, 143.19363403320312); }
&[data-tn-seat-id="529346"] { transform: matrix(1, 0, 0, 1, -1740.5440673828125, 92.78125); }


// Row C
&[data-tn-seat-id="529246"] { transform: matrix(1, 0, 0, 1, -35.485595703125, 1579.5); }
&[data-tn-seat-id="529247"] { transform: matrix(1, 0, 0, 1, -139.4176025390625, 1530.7744140625); }
&[data-tn-seat-id="529248"] { transform: matrix(1, 0, 0, 1, -240.6680908203125, 1480.3851318359375); }
&[data-tn-seat-id="529249"] { transform: matrix(1, 0, 0, 1, -339.7296142578125, 1428.7861328125); }
&[data-tn-seat-id="529250"] { transform: matrix(1, 0, 0, 1, -436.6953125, 1376.1407470703125); }
&[data-tn-seat-id="529251"] { transform: matrix(1, 0, 0, 1, -531.5642700195312, 1322.55126953125); }
&[data-tn-seat-id="529252"] { transform: matrix(1, 0, 0, 1, -624.2930908203125, 1268.098388671875); }
&[data-tn-seat-id="529253"] { transform: matrix(1, 0, 0, 1, -714.8112182617188, 1212.8546142578125); }
&[data-tn-seat-id="529254"] { transform: matrix(1, 0, 0, 1, -803.0301513671875, 1156.8929443359375); }
&[data-tn-seat-id="529255"] { transform: matrix(1, 0, 0, 1, -888.85009765625, 1100.2913818359375); }
&[data-tn-seat-id="529256"] { transform: matrix(1, 0, 0, 1, -972.1661376953125, 1043.136962890625); }
&[data-tn-seat-id="529257"] { transform: matrix(1, 0, 0, 1, -1052.8705444335938, 985.527099609375); }
&[data-tn-seat-id="529258"] { transform: matrix(1, 0, 0, 1, -1130.8549194335938, 927.5718994140625); }
&[data-tn-seat-id="529259"] { transform: matrix(1, 0, 0, 1, -1206.0224609375, 869.39501953125); }
&[data-tn-seat-id="529260"] { transform: matrix(1, 0, 0, 1, -1278.2813720703125, 811.133056640625); }
&[data-tn-seat-id="529261"] { transform: matrix(1, 0, 0, 1, -1347.5634765625, 752.9341430664062); }
&[data-tn-seat-id="529262"] { transform: matrix(1, 0, 0, 1, -1413.8170776367188, 694.9561767578125); }
&[data-tn-seat-id="529263"] { transform: matrix(1, 0, 0, 1, -1477.0159301757812, 637.3627319335938); }
&[data-tn-seat-id="529264"] { transform: matrix(1, 0, 0, 1, -1537.1626586914062, 580.3193969726562); }
&[data-tn-seat-id="529265"] { transform: matrix(1, 0, 0, 1, -1594.2872924804688, 523.9886474609375); }
&[data-tn-seat-id="529266"] { transform: matrix(1, 0, 0, 1, -1648.452880859375, 468.5233154296875); }
&[data-tn-seat-id="529267"] { transform: matrix(1, 0, 0, 1, -1699.7434692382812, 414.06573486328125); }
&[data-tn-seat-id="529268"] { transform: matrix(1, 0, 0, 1, -1748.268798828125, 360.74163818359375); }
&[data-tn-seat-id="529269"] { transform: matrix(1, 0, 0, 1, -1794.164306640625, 308.6541442871094); }
&[data-tn-seat-id="529270"] { transform: matrix(1, 0, 0, 1, -1837.5701904296875, 257.8900146484375); }
&[data-tn-seat-id="529271"] { transform: matrix(1, 0, 0, 1, -1878.6407470703125, 208.513427734375); }
&[data-tn-seat-id="529272"] { transform: matrix(1, 0, 0, 1, -1917.5386962890625, 160.56588745117188); }
&[data-tn-seat-id="529273"] { transform: matrix(1, 0, 0, 1, -1954.4193115234375, 114.07501220703125); }

// Row C - Additional companion seats (only visible when logged into access user account)

// Row D
&[data-tn-seat-id="529170"] { transform: matrix(1, 0, 0, 1, -39.2845458984375, 1714.125); }
&[data-tn-seat-id="529171"] { transform: matrix(1, 0, 0, 1, -142.0792236328125, 1664.0880126953125); }
&[data-tn-seat-id="529172"] { transform: matrix(1, 0, 0, 1, -242.47308349609375, 1612.64404296875); }
&[data-tn-seat-id="529173"] { transform: matrix(1, 0, 0, 1, -340.93841552734375, 1560.18115234375); }
&[data-tn-seat-id="529174"] { transform: matrix(1, 0, 0, 1, -437.57720947265625, 1506.835693359375); }
&[data-tn-seat-id="529175"] { transform: matrix(1, 0, 0, 1, -532.4107055664062, 1452.69287109375); }
&[data-tn-seat-id="529176"] { transform: matrix(1, 0, 0, 1, -625.4088745117188, 1397.8121337890625); }
&[data-tn-seat-id="529177"] { transform: matrix(1, 0, 0, 1, -716.5300903320312, 1342.2498779296875); }
&[data-tn-seat-id="529178"] { transform: matrix(1, 0, 0, 1, -805.7044677734375, 1286.0552978515625); }
&[data-tn-seat-id="529179"] { transform: matrix(1, 0, 0, 1, -892.856689453125, 1229.2822265625); }
&[data-tn-seat-id="529180"] { transform: matrix(1, 0, 0, 1, -977.9002685546875, 1171.98828125); }
&[data-tn-seat-id="529181"] { transform: matrix(1, 0, 0, 1, -1060.7409057617188, 1114.2373046875); }
&[data-tn-seat-id="529182"] { transform: matrix(1, 0, 0, 1, -1141.2802124023438, 1056.1016845703125); }
&[data-tn-seat-id="529183"] { transform: matrix(1, 0, 0, 1, -1219.4217529296875, 997.6644287109375); }
&[data-tn-seat-id="529184"] { transform: matrix(1, 0, 0, 1, -1295.0650024414062, 939.0186767578125); }
&[data-tn-seat-id="529185"] { transform: matrix(1, 0, 0, 1, -1368.1174926757812, 880.2697143554688); }
&[data-tn-seat-id="529186"] { transform: matrix(1, 0, 0, 1, -1438.4953002929688, 821.5338745117188); }
&[data-tn-seat-id="529187"] { transform: matrix(1, 0, 0, 1, -1506.124267578125, 762.9387817382812); }
&[data-tn-seat-id="529188"] { transform: matrix(1, 0, 0, 1, -1570.9454345703125, 704.6214599609375); }
&[data-tn-seat-id="529189"] { transform: matrix(1, 0, 0, 1, -1632.92138671875, 646.725341796875); }
&[data-tn-seat-id="529190"] { transform: matrix(1, 0, 0, 1, -1692.0379028320312, 589.39794921875); }
&[data-tn-seat-id="529191"] { transform: matrix(1, 0, 0, 1, -1748.3006591796875, 532.7874755859375); }
&[data-tn-seat-id="529192"] { transform: matrix(1, 0, 0, 1, -1801.740966796875, 477.03851318359375); }
&[data-tn-seat-id="529193"] { transform: matrix(1, 0, 0, 1, -1852.4177856445312, 422.28704833984375); }
&[data-tn-seat-id="529194"] { transform: matrix(1, 0, 0, 1, -1900.4132690429688, 368.65673828125); }
&[data-tn-seat-id="529195"] { transform: matrix(1, 0, 0, 1, -1945.82666015625, 316.2581481933594); }
&[data-tn-seat-id="529196"] { transform: matrix(1, 0, 0, 1, -1988.7754516601562, 265.18414306640625); }
&[data-tn-seat-id="529197"] { transform: matrix(1, 0, 0, 1, -2029.3935546875, 215.50823974609375); }
&[data-tn-seat-id="529198"] { transform: matrix(1, 0, 0, 1, -2067.817626953125, 167.28750610351562); }

// Row D - Additional companion seats (only visible when logged into access user account)


// Row E
&[data-tn-seat-id="529094"] { transform: matrix(1, 0, 0, 1, -35.016357421875, 1851.2750244140625); }
&[data-tn-seat-id="529095"] { transform: matrix(1, 0, 0, 1, -139.27587890625, 1801.098876953125); }
&[data-tn-seat-id="529096"] { transform: matrix(1, 0, 0, 1, -241.09716796875, 1749.4293212890625); }
&[data-tn-seat-id="529097"] { transform: matrix(1, 0, 0, 1, -340.96856689453125, 1696.6815185546875); }
&[data-tn-seat-id="529098"] { transform: matrix(1, 0, 0, 1, -439.007080078125, 1643.0048828125); }
&[data-tn-seat-id="529099"] { transform: matrix(1, 0, 0, 1, -535.2384643554688, 1588.488525390625); }
&[data-tn-seat-id="529100"] { transform: matrix(1, 0, 0, 1, -629.651123046875, 1533.19970703125); }
&[data-tn-seat-id="529101"] { transform: matrix(1, 0, 0, 1, -722.2047729492188, 1477.193115234375); }
&[data-tn-seat-id="529102"] { transform: matrix(1, 0, 0, 1, -812.8482055664062, 1420.522216796875); }
&[data-tn-seat-id="529103"] { transform: matrix(1, 0, 0, 1, -901.5170288085938, 1363.2393798828125); }
&[data-tn-seat-id="529104"] { transform: matrix(1, 0, 0, 1, -988.138916015625, 1305.4005126953125); }
&[data-tn-seat-id="529105"] { transform: matrix(1, 0, 0, 1, -1072.6370849609375, 1247.0660400390625); }
&[data-tn-seat-id="529106"] { transform: matrix(1, 0, 0, 1, -1154.9310302734375, 1188.3028564453125); }
&[data-tn-seat-id="529107"] { transform: matrix(1, 0, 0, 1, -1234.9382934570312, 1129.1846923828125); }
&[data-tn-seat-id="529108"] { transform: matrix(1, 0, 0, 1, -1312.5797729492188, 1069.79443359375); }
&[data-tn-seat-id="529109"] { transform: matrix(1, 0, 0, 1, -1387.7800903320312, 1010.2230224609375); }
&[data-tn-seat-id="529110"] { transform: matrix(1, 0, 0, 1, -1460.466064453125, 950.56982421875); }
&[data-tn-seat-id="529111"] { transform: matrix(1, 0, 0, 1, -1530.5812377929688, 890.9428100585938); }
&[data-tn-seat-id="529112"] { transform: matrix(1, 0, 0, 1, -1598.0757446289062, 831.45654296875); }
&[data-tn-seat-id="529113"] { transform: matrix(1, 0, 0, 1, -1662.9166259765625, 772.231201171875); }
&[data-tn-seat-id="529114"] { transform: matrix(1, 0, 0, 1, -1725.0841674804688, 713.3905029296875); }
&[data-tn-seat-id="529115"] { transform: matrix(1, 0, 0, 1, -1784.5817260742188, 655.058837890625); }
&[data-tn-seat-id="529116"] { transform: matrix(1, 0, 0, 1, -1841.426025390625, 597.3596801757812); }
&[data-tn-seat-id="529117"] { transform: matrix(1, 0, 0, 1, -1895.6560668945312, 540.4113159179688); }
&[data-tn-seat-id="529118"] { transform: matrix(1, 0, 0, 1, -1947.3310546875, 484.32373046875); }
&[data-tn-seat-id="529119"] { transform: matrix(1, 0, 0, 1, -1996.5189208984375, 429.2008361816406); }
&[data-tn-seat-id="529120"] { transform: matrix(1, 0, 0, 1, -2043.31103515625, 375.1310729980469); }
&[data-tn-seat-id="529121"] { transform: matrix(1, 0, 0, 1, -2087.8054809570312, 322.19293212890625); }
&[data-tn-seat-id="529122"] { transform: matrix(1, 0, 0, 1, -2130.1126098632812, 270.4499816894531); }
&[data-tn-seat-id="529123"] { transform: matrix(1, 0, 0, 1, -2170.3472290039062, 219.9522705078125); }
&[data-tn-seat-id="529124"] { transform: matrix(1, 0, 0, 1, -2208.6302490234375, 170.73631286621094); }
&[data-tn-seat-id="529125"] { transform: matrix(1, 0, 0, 1, -2245.0833740234375, 122.82499694824219); }


// Row Z
&[data-tn-seat-id="529504"] { transform: matrix(1, 0, 0, 1, -2200.1466064453125, -264.20355224609375); }
&[data-tn-seat-id="529579"] { transform: matrix(1, 0, 0, 1, -2151.532470703125, -374.04400634765625); }
&[data-tn-seat-id="529654"] { transform: matrix(1, 0, 0, 1, -2099.5863037109375, -479.14158630371094); }

// Row Y
&[data-tn-seat-id="529578"] { transform: matrix(1, 0, 0, 1, -2088.2149658203125, -271.6460266113281); }
&[data-tn-seat-id="529653"] { transform: matrix(1, 0, 0, 1, -2040.2095336914062, -380.0401611328125); }
&[data-tn-seat-id="529728"] { transform: matrix(1, 0, 0, 1, -1988.481689453125, -482.9713439941406); }
&[data-tn-seat-id="529803"] { transform: matrix(1, 0, 0, 1, -1934.23193359375, -581.2386779785156); }
&[data-tn-seat-id="529878"] { transform: matrix(1, 0, 0, 1, -1878.0164794921875, -674.9881896972656); }
&[data-tn-seat-id="529953"] { transform: matrix(1, 0, 0, 1, -1820.2698364257812, -764.2703399658203); }
&[data-tn-seat-id="530028"] { transform: matrix(1, 0, 0, 1, -1761.377197265625, -849.1203002929688); }
&[data-tn-seat-id="530103"] { transform: matrix(1, 0, 0, 1, -1701.6982421875, -929.6043395996094); }
&[data-tn-seat-id="530178"] { transform: matrix(1, 0, 0, 1, -1641.5663452148438, -1005.8286437988281); }
&[data-tn-seat-id="530253"] { transform: matrix(1, 0, 0, 1, -1581.2852783203125, -1077.9408721923828); }
&[data-tn-seat-id="530328"] { transform: matrix(1, 0, 0, 1, -1521.1239013671875, -1146.128402709961); }
&[data-tn-seat-id="530403"] { transform: matrix(1, 0, 0, 1, -1461.3118896484375, -1210.6060638427734); }
&[data-tn-seat-id="530478"] { transform: matrix(1, 0, 0, 1, -1402.0390625, -1271.6106262207031); }
&[data-tn-seat-id="530553"] { transform: matrix(1, 0, 0, 1, -1338.80126953125, -1332.035400390625); }
&[data-tn-seat-id="530628"] { transform: matrix(1, 0, 0, 1, -1280.1630859375, -1394.156982421875); }
&[data-tn-seat-id="530703"] { transform: matrix(1, 0, 0, 1, -1221.6484375, -1455.5704956054688); }
&[data-tn-seat-id="530778"] { transform: matrix(1, 0, 0, 1, -1163.283203125, -1516.1910400390625); }
&[data-tn-seat-id="530853"] { transform: matrix(1, 0, 0, 1, -1105.1466064453125, -1575.6991119384766); }

// Row X
&[data-tn-seat-id="529577"] { transform: matrix(1, 0, 0, 1, -1975.9348754882812, -211.8406982421875); }
&[data-tn-seat-id="529652"] { transform: matrix(1, 0, 0, 1, -1927.901123046875, -327.2871398925781); }
&[data-tn-seat-id="529727"] { transform: matrix(1, 0, 0, 1, -1871.6005859375, -431.6448669433594); }
&[data-tn-seat-id="529802"] { transform: matrix(1, 0, 0, 1, -1811.2463989257812, -527.6932983398438); }
&[data-tn-seat-id="529877"] { transform: matrix(1, 0, 0, 1, -1748.5982055664062, -616.9600219726562); }
&[data-tn-seat-id="529952"] { transform: matrix(1, 0, 0, 1, -1684.6552124023438, -700.5942077636719); }
&[data-tn-seat-id="530027"] { transform: matrix(1, 0, 0, 1, -1620.0247802734375, -779.4980621337891); }
&[data-tn-seat-id="530102"] { transform: matrix(1, 0, 0, 1, -1555.087646484375, -854.3873138427734); }
&[data-tn-seat-id="530177"] { transform: matrix(1, 0, 0, 1, -1490.086181640625, -925.8360137939453); }
&[data-tn-seat-id="530252"] { transform: matrix(1, 0, 0, 1, -1425.176513671875, -994.3078155517578); }
&[data-tn-seat-id="530327"] { transform: matrix(1, 0, 0, 1, -1360.45947265625, -1060.1811218261719); }
&[data-tn-seat-id="530402"] { transform: matrix(1, 0, 0, 1, -1295.9991455078125, -1123.7660522460938); }
&[data-tn-seat-id="530477"] { transform: matrix(1, 0, 0, 1, -1231.835693359375, -1185.3223266601562); }
&[data-tn-seat-id="530552"] { transform: matrix(1, 0, 0, 1, -1167.9930419921875, -1245.0664367675781); }
&[data-tn-seat-id="530627"] { transform: matrix(1, 0, 0, 1, -1104.48486328125, -1303.1803588867188); }
&[data-tn-seat-id="530702"] { transform: matrix(1, 0, 0, 1, -1041.31591796875, -1359.822998046875); }

// Row P
&[data-tn-seat-id="530976"] { transform: matrix(1, 0, 0, 1, 1730.99609375, -187.8333740234375); }
&[data-tn-seat-id="530977"] { transform: matrix(1, 0, 0, 1, 1682.1171875, -242.77392578125); }
&[data-tn-seat-id="530978"] { transform: matrix(1, 0, 0, 1, 1630.496826171875, -298.7962646484375); }
&[data-tn-seat-id="530979"] { transform: matrix(1, 0, 0, 1, 1576.70947265625, -355.5611572265625); }
&[data-tn-seat-id="530980"] { transform: matrix(1, 0, 0, 1, 1520.89697265625, -412.9366455078125); }
&[data-tn-seat-id="530981"] { transform: matrix(1, 0, 0, 1, 1463.108642578125, -470.8333740234375); }
&[data-tn-seat-id="530982"] { transform: matrix(1, 0, 0, 1, 1403.344970703125, -529.1795654296875); }
&[data-tn-seat-id="530983"] { transform: matrix(1, 0, 0, 1, 1341.585205078125, -587.9088134765625); }
&[data-tn-seat-id="530984"] { transform: matrix(1, 0, 0, 1, 1277.78955078125, -646.9566650390625); }
&[data-tn-seat-id="530985"] { transform: matrix(1, 0, 0, 1, 1211.908935546875, -706.2568359375); }
&[data-tn-seat-id="530986"] { transform: matrix(1, 0, 0, 1, 1143.890625, -765.73876953125); }
&[data-tn-seat-id="530987"] { transform: matrix(1, 0, 0, 1, 1073.669921875, -825.3270874023438); }
&[data-tn-seat-id="530988"] { transform: matrix(1, 0, 0, 1, 1001.19140625, -884.9397583007812); }
&[data-tn-seat-id="530989"] { transform: matrix(1, 0, 0, 1, 926.399169921875, -944.48876953125); }
&[data-tn-seat-id="530990"] { transform: matrix(1, 0, 0, 1, 849.228271484375, -1003.8782348632812); }
&[data-tn-seat-id="530991"] { transform: matrix(1, 0, 0, 1, 769.64453125, -1063.0077514648438); }
&[data-tn-seat-id="530992"] { transform: matrix(1, 0, 0, 1, 687.5986328125, -1121.76904296875); }
&[data-tn-seat-id="530993"] { transform: matrix(1, 0, 0, 1, 603.063232421875, -1180.0499877929688); }
&[data-tn-seat-id="530994"] { transform: matrix(1, 0, 0, 1, 516.02392578125, -1237.7355346679688); }
&[data-tn-seat-id="530995"] { transform: matrix(1, 0, 0, 1, 426.4765625, -1294.7081909179688); }
&[data-tn-seat-id="530996"] { transform: matrix(1, 0, 0, 1, 334.4384765625, -1350.8529968261719); }
&[data-tn-seat-id="530997"] { transform: matrix(1, 0, 0, 1, 239.93798828125, -1406.0567321777344); }
&[data-tn-seat-id="530998"] { transform: matrix(1, 0, 0, 1, 143.023681640625, -1460.2142944335938); }


// Row Q
&[data-tn-seat-id="531050"] { transform: matrix(1, 0, 0, 1, 1880.36767578125, -196.2142333984375); }
&[data-tn-seat-id="531051"] { transform: matrix(1, 0, 0, 1, 1833.312744140625, -254.09619140625); }
&[data-tn-seat-id="531052"] { transform: matrix(1, 0, 0, 1, 1783.384765625, -313.1407470703125); }
&[data-tn-seat-id="531053"] { transform: matrix(1, 0, 0, 1, 1731.196044921875, -372.98291015625); }
&[data-tn-seat-id="531054"] { transform: matrix(1, 0, 0, 1, 1676.892333984375, -433.484619140625); }
&[data-tn-seat-id="531055"] { transform: matrix(1, 0, 0, 1, 1620.529296875, -494.551025390625); }
&[data-tn-seat-id="531056"] { transform: matrix(1, 0, 0, 1, 1562.111328125, -556.1070556640625); }
&[data-tn-seat-id="531057"] { transform: matrix(1, 0, 0, 1, 1501.617431640625, -618.0848388671875); }
&[data-tn-seat-id="531058"] { transform: matrix(1, 0, 0, 1, 1439.007568359375, -680.41796875); }
&[data-tn-seat-id="531059"] { transform: matrix(1, 0, 0, 1, 1374.232666015625, -743.0390625); }
&[data-tn-seat-id="531060"] { transform: matrix(1, 0, 0, 1, 1307.24169921875, -805.8759765625); }
&[data-tn-seat-id="531061"] { transform: matrix(1, 0, 0, 1, 1237.969482421875, -868.85302734375); }
&[data-tn-seat-id="531062"] { transform: matrix(1, 0, 0, 1, 1166.355712890625, -931.8876342773438); }
&[data-tn-seat-id="531063"] { transform: matrix(1, 0, 0, 1, 1092.3466796875, -994.8912963867188); }
&[data-tn-seat-id="531064"] { transform: matrix(1, 0, 0, 1, 1015.8779296875, -1057.7681274414062); }
&[data-tn-seat-id="531065"] { transform: matrix(1, 0, 0, 1, 936.9052734375, -1120.4165649414062); }
&[data-tn-seat-id="531066"] { transform: matrix(1, 0, 0, 1, 855.3896484375, -1182.7293090820312); }
&[data-tn-seat-id="531067"] { transform: matrix(1, 0, 0, 1, 771.2880859375, -1244.5919189453125); }
&[data-tn-seat-id="531068"] { transform: matrix(1, 0, 0, 1, 684.5888671875, -1305.8892211914062); }
&[data-tn-seat-id="531069"] { transform: matrix(1, 0, 0, 1, 595.283935546875, -1366.5023193359375); }
&[data-tn-seat-id="531070"] { transform: matrix(1, 0, 0, 1, 503.3828125, -1426.3130493164062); }
&[data-tn-seat-id="531071"] { transform: matrix(1, 0, 0, 1, 408.91455078125, -1485.2073669433594); }
&[data-tn-seat-id="531072"] { transform: matrix(1, 0, 0, 1, 311.9169921875, -1543.0732116699219); }
&[data-tn-seat-id="531073"] { transform: matrix(1, 0, 0, 1, 212.45068359375, -1599.8095092773438); }


// Row R
&[data-tn-seat-id="531124"] { transform: matrix(1, 0, 0, 1, 2055.648193359375, -244.357177734375); }
&[data-tn-seat-id="531125"] { transform: matrix(1, 0, 0, 1, 2005.952880859375, -301.269775390625); }
&[data-tn-seat-id="531126"] { transform: matrix(1, 0, 0, 1, 1953.73193359375, -359.1143798828125); }
&[data-tn-seat-id="531127"] { transform: matrix(1, 0, 0, 1, 1899.54150390625, -417.5963134765625); }
&[data-tn-seat-id="531128"] { transform: matrix(1, 0, 0, 1, 1843.532470703125, -476.6016845703125); }
&[data-tn-seat-id="531129"] { transform: matrix(1, 0, 0, 1, 1785.760986328125, -536.0567626953125); }
&[data-tn-seat-id="531130"] { transform: matrix(1, 0, 0, 1, 1726.241943359375, -595.9031982421875); }
&[data-tn-seat-id="531131"] { transform: matrix(1, 0, 0, 1, 1664.969482421875, -656.088623046875); }
&[data-tn-seat-id="531132"] { transform: matrix(1, 0, 0, 1, 1601.916259765625, -716.5643310546875); }
&[data-tn-seat-id="531133"] { transform: matrix(1, 0, 0, 1, 1537.052001953125, -777.2802734375); }
&[data-tn-seat-id="531134"] { transform: matrix(1, 0, 0, 1, 1470.331787109375, -838.185546875); }
&[data-tn-seat-id="531135"] { transform: matrix(1, 0, 0, 1, 1401.709716796875, -899.2257080078125); }
&[data-tn-seat-id="531136"] { transform: matrix(1, 0, 0, 1, 1331.13671875, -960.3424682617188); }
&[data-tn-seat-id="531137"] { transform: matrix(1, 0, 0, 1, 1258.560791015625, -1021.4730834960938); }
&[data-tn-seat-id="531138"] { transform: matrix(1, 0, 0, 1, 1183.925048828125, -1082.5494384765625); }
&[data-tn-seat-id="531139"] { transform: matrix(1, 0, 0, 1, 1107.181396484375, -1143.4982299804688); }
&[data-tn-seat-id="531140"] { transform: matrix(1, 0, 0, 1, 1028.27783203125, -1204.239990234375); }
&[data-tn-seat-id="531141"] { transform: matrix(1, 0, 0, 1, 947.177001953125, -1264.6913452148438); }
&[data-tn-seat-id="531142"] { transform: matrix(1, 0, 0, 1, 863.8349609375, -1324.7623291015625); }
&[data-tn-seat-id="531143"] { transform: matrix(1, 0, 0, 1, 778.22705078125, -1384.36083984375); }
&[data-tn-seat-id="531144"] { transform: matrix(1, 0, 0, 1, 690.322021484375, -1443.387939453125); }
&[data-tn-seat-id="531145"] { transform: matrix(1, 0, 0, 1, 600.1142578125, -1501.7458190917969); }
&[data-tn-seat-id="531146"] { transform: matrix(1, 0, 0, 1, 507.602294921875, -1559.3338317871094); }
&[data-tn-seat-id="531147"] { transform: matrix(1, 0, 0, 1, 412.798828125, -1616.0535278320312); }
&[data-tn-seat-id="531148"] { transform: matrix(1, 0, 0, 1, 315.731201171875, -1671.8095092773438); }

// Row X
&[data-tn-seat-id="529544"] { transform: matrix(1, 0, 0, 1, 396.19219970703125, 1301.8406982421875); }
&[data-tn-seat-id="529619"] { transform: matrix(1, 0, 0, 1, 446.4119873046875, 1270.6844482421875); }
&[data-tn-seat-id="529694"] { transform: matrix(1, 0, 0, 1, 504.3968505859375, 1227.91015625); }
&[data-tn-seat-id="529769"] { transform: matrix(1, 0, 0, 1, 565.802001953125, 1177.1268310546875); }
&[data-tn-seat-id="529844"] { transform: matrix(1, 0, 0, 1, 629.0281982421875, 1120.1280517578125); }
&[data-tn-seat-id="529919"] { transform: matrix(1, 0, 0, 1, 693.2431640625, 1058.1275634765625); }
&[data-tn-seat-id="529994"] { transform: matrix(1, 0, 0, 1, 757.9700317382812, 992.005859375); }
&[data-tn-seat-id="530069"] { transform: matrix(1, 0, 0, 1, 822.9208984375, 922.4215087890625); }
&[data-tn-seat-id="530144"] { transform: matrix(1, 0, 0, 1, 887.916259765625, 849.8892822265625); }
&[data-tn-seat-id="530219"] { transform: matrix(1, 0, 0, 1, 952.8421630859375, 774.8121337890625); }
&[data-tn-seat-id="530294"] { transform: matrix(1, 0, 0, 1, 1017.6251220703125, 697.5096435546875); }
&[data-tn-seat-id="530369"] { transform: matrix(1, 0, 0, 1, 1082.2181396484375, 618.244384765625); }
&[data-tn-seat-id="530444"] { transform: matrix(1, 0, 0, 1, 1146.5919189453125, 537.239013671875); }
&[data-tn-seat-id="530519"] { transform: matrix(1, 0, 0, 1, 1210.7274169921875, 454.667724609375); }
&[data-tn-seat-id="530594"] { transform: matrix(1, 0, 0, 1, 1274.6156005859375, 370.6903076171875); }

// Row Y
&[data-tn-seat-id="529543"] { transform: matrix(1, 0, 0, 1, 411.14984130859375, 1371.893798828125); }
&[data-tn-seat-id="529618"] { transform: matrix(1, 0, 0, 1, 456.88372802734375, 1337.5999755859375); }
&[data-tn-seat-id="529693"] { transform: matrix(1, 0, 0, 1, 509.84686279296875, 1292.6875); }
&[data-tn-seat-id="529768"] { transform: matrix(1, 0, 0, 1, 565.8235473632812, 1240.9140625); }
&[data-tn-seat-id="529843"] { transform: matrix(1, 0, 0, 1, 623.4090576171875, 1184.0079345703125); }
&[data-tn-seat-id="529918"] { transform: matrix(1, 0, 0, 1, 681.9111938476562, 1123.057373046875); }
&[data-tn-seat-id="529993"] { transform: matrix(1, 0, 0, 1, 740.9432373046875, 1058.815185546875); }
&[data-tn-seat-id="530068"] { transform: matrix(1, 0, 0, 1, 800.2734985351562, 991.8271484375); }
&[data-tn-seat-id="530143"] { transform: matrix(1, 0, 0, 1, 859.7559814453125, 922.507568359375); }
&[data-tn-seat-id="530218"] { transform: matrix(1, 0, 0, 1, 919.2957763671875, 851.1707763671875); }
&[data-tn-seat-id="530293"] { transform: matrix(1, 0, 0, 1, 978.829833984375, 778.07568359375); }
&[data-tn-seat-id="530368"] { transform: matrix(1, 0, 0, 1, 1038.3154296875, 703.42724609375); }
&[data-tn-seat-id="530443"] { transform: matrix(1, 0, 0, 1, 1097.7235107421875, 627.391845703125); }
&[data-tn-seat-id="530518"] { transform: matrix(1, 0, 0, 1, 1157.0335693359375, 550.1077880859375); }
&[data-tn-seat-id="530593"] { transform: matrix(1, 0, 0, 1, 1216.23291015625, 471.6990966796875); }
&[data-tn-seat-id="530668"] { transform: matrix(1, 0, 0, 1, 1275.3123779296875, 392.2642822265625); }
&[data-tn-seat-id="530743"] { transform: matrix(1, 0, 0, 1, 1334.2662353515625, 311.89111328125); }
&[data-tn-seat-id="530818"] { transform: matrix(1, 0, 0, 1, 1393.0911865234375, 230.6549072265625); }

// Row Z
&[data-tn-seat-id="529467"] { transform: matrix(1, 0, 0, 1, 428.1498413085938, 1513.893798828125); }
&[data-tn-seat-id="529542"] { transform: matrix(1, 0, 0, 1, 477.65576171875, 1475.60400390625); }
&[data-tn-seat-id="529617"] { transform: matrix(1, 0, 0, 1, 528.7100830078125, 1434.92041015625); }

// Atkins Box
&[data-tn-seat-id="530485"] { transform: matrix(1, 0, 0, 1, -1428.29248046875, -1192.1190490722656); }
&[data-tn-seat-id="530560"] { transform: matrix(1, 0, 0, 1, -1362.38623046875, -1248.8594055175781); }
&[data-tn-seat-id="530635"] { transform: matrix(1, 0, 0, 1, -1296.466552734375, -1305.6579895019531); }
&[data-tn-seat-id="530710"] { transform: matrix(1, 0, 0, 1, -1230.528076171875, -1362.5383605957031); }
&[data-tn-seat-id="530785"] { transform: matrix(1, 0, 0, 1, -1164.5703125, -1419.5026245117188); }
&[data-tn-seat-id="530860"] { transform: matrix(1, 0, 0, 1, -1098.5888671875, -1476.5714416503906); }

&[data-tn-seat-id="530484"] { transform: matrix(1, 0, 0, 1, -1372.320068359375, -1115.5); }
&[data-tn-seat-id="530559"] { transform: matrix(1, 0, 0, 1, -1308.90380859375, -1173.3250732421875); }
&[data-tn-seat-id="530634"] { transform: matrix(1, 0, 0, 1, -1245.42236328125, -1231.4445190429688); }
&[data-tn-seat-id="530709"] { transform: matrix(1, 0, 0, 1, -1181.876953125, -1289.8597106933594); }
&[data-tn-seat-id="530784"] { transform: matrix(1, 0, 0, 1, -1118.2548828125, -1348.6383361816406); }
&[data-tn-seat-id="530859"] { transform: matrix(1, 0, 0, 1, -1054.53369140625, -1407.9047546386719); }

// Benthall Box
&[data-tn-seat-id="530437"] { transform: matrix(1, 0, 0, 1, 2156.70751953125, 466.4761962890625); }
&[data-tn-seat-id="530512"] { transform: matrix(1, 0, 0, 1, 2220.264892578125, 381.7523193359375); }
&[data-tn-seat-id="530587"] { transform: matrix(1, 0, 0, 1, 2283.822265625, 297.028564453125); }
&[data-tn-seat-id="530662"] { transform: matrix(1, 0, 0, 1, 2347.37939453125, 212.3048095703125); }
&[data-tn-seat-id="530737"] { transform: matrix(1, 0, 0, 1, 2410.936767578125, 127.5809326171875); }
&[data-tn-seat-id="530812"] { transform: matrix(1, 0, 0, 1, 2474.494140625, 42.857177734375); }

&[data-tn-seat-id="530436"] { transform: matrix(1, 0, 0, 1, 2243.70751953125,536.4761962890625); }
&[data-tn-seat-id="530511"] { transform: matrix(1, 0, 0, 1, 2308.6416015625,450.6878662109375); }
&[data-tn-seat-id="530586"] { transform: matrix(1, 0, 0, 1, 2373.683837890625,365.419189453125); }
&[data-tn-seat-id="530661"] { transform: matrix(1, 0, 0, 1, 2438.825439453125,280.646728515625); }
&[data-tn-seat-id="530736"] { transform: matrix(1, 0, 0, 1, 2504.07275390625,196.4251708984375); }
&[data-tn-seat-id="530811"] { transform: matrix(1, 0, 0, 1, 2569.438720703125,112.857177734375); }

}

.tn-syos-seat-map:has([data-tn-seat-id="529335"]),
.tn-syos-seat-map:has([data-tn-seat-id="530219"]) {
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        overflow: visible;
        pointer-events: none;
        background-repeat: no-repeat;
        z-index: 10;
        opacity: 1;
        background-image: url("../img/circle-stage.svg");
        mix-blend-mode: multiply;
        background-size: 19%;
        background-position: 65% 50%;
    }
    .tn-syos-seat-map__non-seat {
        visibility: hidden;
    }
}

.tn-syos-seat-map__seat, .twc-icon {

//
&[data-tn-seat-id="532384"] { transform: matrix(1, 0, 0, 1, 1767.385498046875, 997.877685546875); }
&[data-tn-seat-id="532459"] { transform: matrix(1, 0, 0, 1, 1840.8729248046875, 926.6875); }
&[data-tn-seat-id="532534"] { transform: matrix(1, 0, 0, 1, 1914.363525390625, 855.1400146484375); }
&[data-tn-seat-id="532609"] { transform: matrix(1, 0, 0, 1, 1987.8553466796875, 783.23193359375); }
&[data-tn-seat-id="532684"] { transform: matrix(1, 0, 0, 1, 2061.34619140625, 710.869384765625); }
&[data-tn-seat-id="532759"] { transform: matrix(1, 0, 0, 1, 2134.831298828125, 637.877685546875); }

&[data-tn-seat-id="532386"] {transform: matrix(1, 0, 0, 1, 1704.357421875, 850.6636962890625);}
&[data-tn-seat-id="532461"] {transform: matrix(1, 0, 0, 1, 1773.5728759765625, 778.216064453125);}
&[data-tn-seat-id="532536"] {transform: matrix(1, 0, 0, 1, 1842.788818359375, 705.845947265625);}
&[data-tn-seat-id="532611"] {transform: matrix(1, 0, 0, 1, 1912.005126953125, 633.5474853515625);}
&[data-tn-seat-id="532686"] {transform: matrix(1, 0, 0, 1, 1981.2216796875, 561.311767578125);}
&[data-tn-seat-id="532761"] {transform: matrix(1, 0, 0, 1, 2050.4384765625, 489.1412353515625);}
&[data-tn-seat-id="532836"] {transform: matrix(1, 0, 0, 1, 2119.6552734375, 417.03857421875);}
&[data-tn-seat-id="532911"] {transform: matrix(1, 0, 0, 1, 2188.8720703125, 345.0074462890625);}
&[data-tn-seat-id="532986"] {transform: matrix(1, 0, 0, 1, 2258.089111328125, 273.04248046875);}
&[data-tn-seat-id="533061"] {transform: matrix(1, 0, 0, 1, 2327.305908203125, 201.16357421875);}
&[data-tn-seat-id="533136"] {transform: matrix(1, 0, 0, 1, 2396.5224609375, 129.3787841796875);}
&[data-tn-seat-id="533211"] {transform: matrix(1, 0, 0, 1, 2465.73876953125, 57.6922607421875);}
&[data-tn-seat-id="533286"] {transform: matrix(1, 0, 0, 1, 2534.95458984375, -13.884765625);}
&[data-tn-seat-id="533361"] {transform: matrix(1, 0, 0, 1, 2604.169189453125, -85.3363037109375);}

&[data-tn-seat-id="532386"] { transform: matrix(1, 0, 0, 1, 1623.385498046875, 922.5107421875); }
&[data-tn-seat-id="532461"] { transform: matrix(1, 0, 0, 1, 1696.3050537109375, 850.55224609375); }
&[data-tn-seat-id="532536"] { transform: matrix(1, 0, 0, 1, 1769.224609375, 778.631591796875); }
&[data-tn-seat-id="532611"] { transform: matrix(1, 0, 0, 1, 1842.1441650390625, 706.7413330078125); }
&[data-tn-seat-id="532686"] { transform: matrix(1, 0, 0, 1, 1915.063720703125, 634.8726806640625); }
&[data-tn-seat-id="532761"] { transform: matrix(1, 0, 0, 1, 1987.98291015625, 563.0478515625); }
&[data-tn-seat-id="532836"] { transform: matrix(1, 0, 0, 1, 2060.90234375, 491.2327880859375); }
&[data-tn-seat-id="532911"] { transform: matrix(1, 0, 0, 1, 2133.82177734375, 419.4720458984375); }
&[data-tn-seat-id="532986"] { transform: matrix(1, 0, 0, 1, 2206.74072265625, 347.722412109375); }
&[data-tn-seat-id="533061"] { transform: matrix(1, 0, 0, 1, 2279.659912109375, 276.01025390625); }
&[data-tn-seat-id="533136"] { transform: matrix(1, 0, 0, 1, 2352.578857421875, 204.3419189453125); }
&[data-tn-seat-id="533211"] { transform: matrix(1, 0, 0, 1, 2425.497314453125, 132.714111328125); }
&[data-tn-seat-id="533286"] { transform: matrix(1, 0, 0, 1, 2498.415771484375, 61.1307373046875); }
&[data-tn-seat-id="533361"] { transform: matrix(1, 0, 0, 1, 2571.333984375, -10.40283203125); }

//
&[data-tn-seat-id="532387"] {transform: matrix(1, 0, 0, 1, 1636.1065673828125, 760.7423095703125);}
&[data-tn-seat-id="532462"] {transform: matrix(1, 0, 0, 1, 1705.1982421875, 689.230712890625);}
&[data-tn-seat-id="532537"] {transform: matrix(1, 0, 0, 1, 1774.2900390625, 617.6739501953125);}
&[data-tn-seat-id="532612"] {transform: matrix(1, 0, 0, 1, 1843.382080078125, 546.0875244140625);}
&[data-tn-seat-id="532687"] {transform: matrix(1, 0, 0, 1, 1912.474365234375, 474.4716796875);}
&[data-tn-seat-id="532762"] {transform: matrix(1, 0, 0, 1, 1981.56689453125, 402.818603515625);}
&[data-tn-seat-id="532837"] {transform: matrix(1, 0, 0, 1, 2050.659423828125, 331.143310546875);}
&[data-tn-seat-id="532912"] {transform: matrix(1, 0, 0, 1, 2119.752197265625, 259.4420166015625);}
&[data-tn-seat-id="532987"] {transform: matrix(1, 0, 0, 1, 2188.844970703125, 187.7020263671875);}
&[data-tn-seat-id="533062"] {transform: matrix(1, 0, 0, 1, 2257.93798828125, 115.9306640625);}
&[data-tn-seat-id="533137"] {transform: matrix(1, 0, 0, 1, 2327.03125, 44.1224365234375);}
&[data-tn-seat-id="533212"] {transform: matrix(1, 0, 0, 1, 2396.124267578125, -27.72802734375);}
&[data-tn-seat-id="533287"] {transform: matrix(1, 0, 0, 1, 2465.217529296875, -99.615966796875);}
&[data-tn-seat-id="533362"] {transform: matrix(1, 0, 0, 1, 2534.31103515625, -171.5428466796875);}
&[data-tn-seat-id="533437"] {transform: matrix(1, 0, 0, 1, 2603.404296875, -243.5108642578125);}

&[data-tn-seat-id="532387"] { transform: matrix(1, 0, 0, 1, 1551.385498046875, 844.6114501953125); }
&[data-tn-seat-id="532462"] { transform: matrix(1, 0, 0, 1, 1623.7271728515625, 772.2423095703125); }
&[data-tn-seat-id="532537"] { transform: matrix(1, 0, 0, 1, 1696.0692138671875, 699.9508056640625); }
&[data-tn-seat-id="532612"] { transform: matrix(1, 0, 0, 1, 1768.411376953125, 627.699951171875); }
&[data-tn-seat-id="532687"] { transform: matrix(1, 0, 0, 1, 1840.75390625, 555.51025390625); }
&[data-tn-seat-id="532762"] { transform: matrix(1, 0, 0, 1, 1913.09619140625, 483.3719482421875); }
&[data-tn-seat-id="532837"] { transform: matrix(1, 0, 0, 1, 1985.438720703125, 411.28515625); }
&[data-tn-seat-id="532912"] { transform: matrix(1, 0, 0, 1, 2057.781494140625, 339.249755859375); }
&[data-tn-seat-id="532987"] { transform: matrix(1, 0, 0, 1, 2130.1240234375, 267.265869140625); }
&[data-tn-seat-id="533062"] { transform: matrix(1, 0, 0, 1, 2202.466552734375, 195.3336181640625); }
&[data-tn-seat-id="533137"] { transform: matrix(1, 0, 0, 1, 2274.809326171875, 123.4578857421875); }
&[data-tn-seat-id="533212"] { transform: matrix(1, 0, 0, 1, 2347.151611328125, 51.6473388671875); }
&[data-tn-seat-id="533287"] { transform: matrix(1, 0, 0, 1, 2419.493896484375, -20.103515625); }
&[data-tn-seat-id="533362"] { transform: matrix(1, 0, 0, 1, 2491.835693359375, -91.77880859375); }
&[data-tn-seat-id="533437"] { transform: matrix(1, 0, 0, 1, 2564.17724609375, -163.3885498046875); }

//
&[data-tn-seat-id="532244"] { transform: matrix(1, 0, 0, 1, 802.269775390625, 1029.3121337890625); }
&[data-tn-seat-id="532245"] { transform: matrix(1, 0, 0, 1, 670.579833984375, 1009.6795654296875); }
&[data-tn-seat-id="532246"] { transform: matrix(1, 0, 0, 1, 541.0078125, 984.453125); }
&[data-tn-seat-id="532247"] { transform: matrix(1, 0, 0, 1, 413.604248046875, 954.76025390625); }
&[data-tn-seat-id="532248"] { transform: matrix(1, 0, 0, 1, 288.61572265625, 920.945068359375); }
&[data-tn-seat-id="532249"] { transform: matrix(1, 0, 0, 1, 166.33740234375, 883.2178955078125); }
&[data-tn-seat-id="532250"] { transform: matrix(1, 0, 0, 1, 47.082275390625, 841.7669677734375); }
&[data-tn-seat-id="532251"] { transform: matrix(1, 0, 0, 1, -68.8441162109375, 796.80419921875); }
&[data-tn-seat-id="532252"] { transform: matrix(1, 0, 0, 1, -181.168212890625, 748.584228515625); }
&[data-tn-seat-id="532253"] { transform: matrix(1, 0, 0, 1, -289.667724609375, 697.4078369140625); }
&[data-tn-seat-id="532254"] { transform: matrix(1, 0, 0, 1, -394.189697265625, 643.6153564453125); }
&[data-tn-seat-id="532255"] { transform: matrix(1, 0, 0, 1, -494.656982421875, 587.5704345703125); }
&[data-tn-seat-id="532256"] { transform: matrix(1, 0, 0, 1, -591.0712280273438, 529.6436767578125); }
&[data-tn-seat-id="532257"] { transform: matrix(1, 0, 0, 1, -683.5055541992188, 470.1951904296875); }
&[data-tn-seat-id="532258"] { transform: matrix(1, 0, 0, 1, -772.0927734375, 409.5589599609375); }

&[data-tn-seat-id="532259"] { transform: matrix(1, 0, 0, 1, -850.7607, 342.9292); }

&[data-tn-seat-id="532260"] { transform: matrix(1, 0, 0, 1, -915.428466796875, 276.2994384765625); }
&[data-tn-seat-id="532261"] { transform: matrix(1, 0, 0, 1, -976.0256958007812, 214.68878173828125); }
&[data-tn-seat-id="532262"] { transform: matrix(1, 0, 0, 1, -1030.9813232421875, 154.374267578125); }
&[data-tn-seat-id="532263"] { transform: matrix(1, 0, 0, 1, -1081.374267578125, 95.53753662109375); }
&[data-tn-seat-id="532264"] { transform: matrix(1, 0, 0, 1, -1127.50146484375, 38.44757080078125); }
&[data-tn-seat-id="532265"] { transform: matrix(1, 0, 0, 1, -1169.5115966796875, -16.59124755859375); }
&[data-tn-seat-id="532266"] { transform: matrix(1, 0, 0, 1, -1207.531982421875, -69.26016235351562); }
&[data-tn-seat-id="532267"] { transform: matrix(1, 0, 0, 1, -1241.7060546875, -119.2379150390625); }
&[data-tn-seat-id="532268"] { transform: matrix(1, 0, 0, 1, -1272.2203369140625, -166.22198486328125); }
&[data-tn-seat-id="532269"] { transform: matrix(1, 0, 0, 1, -1299.311767578125, -209.94613647460938); }
&[data-tn-seat-id="532270"] { transform: matrix(1, 0, 0, 1, -1323.26416015625, -250.19631958007812); }
&[data-tn-seat-id="532271"] { transform: matrix(1, 0, 0, 1, -1344.40185546875, -286.8262023925781); }
&[data-tn-seat-id="532272"] { transform: matrix(1, 0, 0, 1, -1363.0716552734375, -319.7616882324219); }
&[data-tn-seat-id="532273"] { transform: matrix(1, 0, 0, 1, -1379.626220703125, -348.998779296875); }
&[data-tn-seat-id="532274"] { transform: matrix(1, 0, 0, 1, -1394.408447265625, -374.5970916748047); }

//
&[data-tn-seat-id="532166"] { transform: matrix(1, 0, 0, 1, 735.412353515625, 1136.52880859375); }
&[data-tn-seat-id="532167"] { transform: matrix(1, 0, 0, 1, 613.3741455078125, 1106.882568359375); }
&[data-tn-seat-id="532168"] { transform: matrix(1, 0, 0, 1, 492.5592041015625, 1075.2559814453125); }
&[data-tn-seat-id="532169"] { transform: matrix(1, 0, 0, 1, 372.8240966796875, 1042.0216064453125); }
&[data-tn-seat-id="532170"] { transform: matrix(1, 0, 0, 1, 254.1973876953125, 1007.2493896484375); }
&[data-tn-seat-id="532171"] { transform: matrix(1, 0, 0, 1, 136.764892578125, 970.9310302734375); }
&[data-tn-seat-id="532172"] { transform: matrix(1, 0, 0, 1, 20.6495361328125, 933.0242919921875); }
&[data-tn-seat-id="532173"] { transform: matrix(1, 0, 0, 1, -93.9801025390625, 893.455078125); }
&[data-tn-seat-id="532174"] { transform: matrix(1, 0, 0, 1, -206.916015625, 852.1370849609375); }
&[data-tn-seat-id="532175"] { transform: matrix(1, 0, 0, 1, -317.8958740234375, 808.970458984375); }
&[data-tn-seat-id="532176"] { transform: matrix(1, 0, 0, 1, -426.593017578125, 763.84912109375); }
&[data-tn-seat-id="532177"] { transform: matrix(1, 0, 0, 1, -532.608642578125, 716.6759033203125); }
&[data-tn-seat-id="532178"] { transform: matrix(1, 0, 0, 1, -635.45068359375, 667.372314453125); }
&[data-tn-seat-id="532179"] { transform: matrix(1, 0, 0, 1, -734.5369873046875, 615.9134521484375); }
&[data-tn-seat-id="532180"] { transform: matrix(1, 0, 0, 1, -829.19189453125, 562.3602294921875); }
&[data-tn-seat-id="532181"] { transform: matrix(1, 0, 0, 1, -918.677001953125, 506.90838623046875); }

&[data-tn-seat-id="532182"] { transform: matrix(1, 0, 0, 1, -1002.2646484375, 449.9361572265625); }

&[data-tn-seat-id="532183"] { transform: matrix(1, 0, 0, 1, -1079.342529296875, 392.02374267578125); }
&[data-tn-seat-id="532184"] { transform: matrix(1, 0, 0, 1, -1149.5465087890625, 333.91851806640625); }
&[data-tn-seat-id="532185"] { transform: matrix(1, 0, 0, 1, -1212.8563232421875, 276.43829345703125); }
&[data-tn-seat-id="532186"] { transform: matrix(1, 0, 0, 1, -1269.6097412109375, 220.33587646484375); }
&[data-tn-seat-id="532187"] { transform: matrix(1, 0, 0, 1, -1320.41796875, 166.19354248046875); }
&[data-tn-seat-id="532188"] { transform: matrix(1, 0, 0, 1, -1366.0382080078125, 114.37481689453125); }
&[data-tn-seat-id="532189"] { transform: matrix(1, 0, 0, 1, -1407.2496337890625, 65.042724609375); }
&[data-tn-seat-id="532190"] { transform: matrix(1, 0, 0, 1, -1444.7713623046875, 18.210479736328125); }
&[data-tn-seat-id="532191"] { transform: matrix(1, 0, 0, 1, -1479.229248046875, -26.20745849609375); }
&[data-tn-seat-id="532192"] { transform: matrix(1, 0, 0, 1, -1511.1463623046875, -68.34954833984375); }
&[data-tn-seat-id="532193"] { transform: matrix(1, 0, 0, 1, -1540.9501953125, -108.37823486328125); }
&[data-tn-seat-id="532194"] { transform: matrix(1, 0, 0, 1, -1568.986572265625, -146.459228515625); }
&[data-tn-seat-id="532195"] { transform: matrix(1, 0, 0, 1, -1595.5357666015625, -182.75360107421875); }
&[data-tn-seat-id="532196"] { transform: matrix(1, 0, 0, 1, -1620.82373046875, -217.4111785888672); }
&[data-tn-seat-id="532197"] { transform: matrix(1, 0, 0, 1, -1645.03369140625, -250.56777954101562); }
&[data-tn-seat-id="532198"] { transform: matrix(1, 0, 0, 1, -1668.3189697265625, -282.3500061035156); }
&[data-tn-seat-id="532199"] { transform: matrix(1, 0, 0, 1, -1690.80224609375, -312.8687744140625); }
&[data-tn-seat-id="532200"] { transform: matrix(1, 0, 0, 1, -1712.587646484375, -342.22550201416016); }

//
&[data-tn-seat-id="532166"] { transform: matrix(1, 0, 0, 1, 916.8013916015625, 1192.94189453125); }
&[data-tn-seat-id="532167"] { transform: matrix(1, 0, 0, 1, 788.885009765625, 1168.69482421875); }
&[data-tn-seat-id="532168"] { transform: matrix(1, 0, 0, 1, 662.4619140625, 1141.243408203125); }
&[data-tn-seat-id="532169"] { transform: matrix(1, 0, 0, 1, 537.442138671875, 1111.169677734375); }
&[data-tn-seat-id="532170"] { transform: matrix(1, 0, 0, 1, 413.9300537109375, 1078.581298828125); }
&[data-tn-seat-id="532171"] { transform: matrix(1, 0, 0, 1, 292.1009521484375, 1043.4742431640625); }
&[data-tn-seat-id="532172"] { transform: matrix(1, 0, 0, 1, 172.185546875, 1005.7989501953125); }
&[data-tn-seat-id="532173"] { transform: matrix(1, 0, 0, 1, 54.46728515625, 965.4876708984375); }
&[data-tn-seat-id="532174"] { transform: matrix(1, 0, 0, 1, -60.7105712890625, 922.46826171875); }
&[data-tn-seat-id="532175"] { transform: matrix(1, 0, 0, 1, -172.95281982421875, 876.693359375); }
&[data-tn-seat-id="532176"] { transform: matrix(1, 0, 0, 1, -281.8154296875, 828.157958984375); }
&[data-tn-seat-id="532177"] { transform: matrix(1, 0, 0, 1, -386.8162841796875, 776.92138671875); }
&[data-tn-seat-id="532178"] { transform: matrix(1, 0, 0, 1, -487.46710205078125, 723.1337890625); }
&[data-tn-seat-id="532179"] { transform: matrix(1, 0, 0, 1, -583.3103637695312, 667.0511474609375); }
&[data-tn-seat-id="532180"] { transform: matrix(1, 0, 0, 1, -673.9684448242188, 609.0384521484375); }
&[data-tn-seat-id="532181"] { transform: matrix(1, 0, 0, 1, -759.1943359375, 549.5519409179688); }
&[data-tn-seat-id="532182"] { transform: matrix(1, 0, 0, 1, -838.9037475585938, 489.10162353515625); }

&[data-tn-seat-id="532183"] { transform: matrix(1, 0, 0, 1, -914.342529, 424.023743); }

&[data-tn-seat-id="532184"] { transform: matrix(1, 0, 0, 1, -981.015625, 360.18511962890625); }
&[data-tn-seat-id="532185"] { transform: matrix(1, 0, 0, 1, -1044.3890991210938, 298.13507080078125); }
&[data-tn-seat-id="532186"] { transform: matrix(1, 0, 0, 1, -1101.7266845703125, 237.2041015625); }
&[data-tn-seat-id="532187"] { transform: matrix(1, 0, 0, 1, -1154.2875366210938, 177.62158203125); }
&[data-tn-seat-id="532188"] { transform: matrix(1, 0, 0, 1, -1202.4744262695312, 119.64715576171875); }
&[data-tn-seat-id="532189"] { transform: matrix(1, 0, 0, 1, -1246.5281982421875, 63.54736328125); }
&[data-tn-seat-id="532190"] { transform: matrix(1, 0, 0, 1, -1286.6533203125, 9.582489013671875); }
&[data-tn-seat-id="532191"] { transform: matrix(1, 0, 0, 1, -1323.057861328125, -42.004608154296875); }
&[data-tn-seat-id="532192"] { transform: matrix(1, 0, 0, 1, -1355.96826171875, -90.99771118164062); }
&[data-tn-seat-id="532193"] { transform: matrix(1, 0, 0, 1, -1385.6385498046875, -137.2205810546875); }
&[data-tn-seat-id="532194"] { transform: matrix(1, 0, 0, 1, -1412.3411865234375, -180.54061889648438); }
&[data-tn-seat-id="532195"] { transform: matrix(1, 0, 0, 1, -1436.3642578125, -220.87393188476562); }
&[data-tn-seat-id="532196"] { transform: matrix(1, 0, 0, 1, -1458.0001220703125, -258.18519592285156); }
&[data-tn-seat-id="532197"] { transform: matrix(1, 0, 0, 1, -1477.535888671875, -292.4822540283203); }
&[data-tn-seat-id="532198"] { transform: matrix(1, 0, 0, 1, -1495.2467041015625, -323.81285095214844); }
&[data-tn-seat-id="532199"] { transform: matrix(1, 0, 0, 1, -1511.3896484375, -352.2554931640625); }
&[data-tn-seat-id="532200"] { transform: matrix(1, 0, 0, 1, -1526.1986083984375, -377.9128875732422); }

//
&[data-tn-seat-id="532094"] { transform: matrix(1, 0, 0, 1, 332.37548828125, 1235.6842041015625); }
&[data-tn-seat-id="532095"] { transform: matrix(1, 0, 0, 1, 214.84576416015625, 1194.4554443359375); }
&[data-tn-seat-id="532096"] { transform: matrix(1, 0, 0, 1, 100.234130859375, 1150.2020263671875); }
&[data-tn-seat-id="532097"] { transform: matrix(1, 0, 0, 1, -11.82427978515625, 1103.6231689453125); }
&[data-tn-seat-id="532098"] { transform: matrix(1, 0, 0, 1, -121.30780029296875, 1054.947998046875); }
&[data-tn-seat-id="532099"] { transform: matrix(1, 0, 0, 1, -228.0955810546875, 1004.3114013671875); }
&[data-tn-seat-id="532100"] { transform: matrix(1, 0, 0, 1, -332.01324462890625, 951.8189697265625); }
&[data-tn-seat-id="532101"] { transform: matrix(1, 0, 0, 1, -432.855712890625, 897.5765380859375); }
&[data-tn-seat-id="532102"] { transform: matrix(1, 0, 0, 1, -530.4067993164062, 841.709228515625); }
&[data-tn-seat-id="532103"] { transform: matrix(1, 0, 0, 1, -624.436767578125, 784.3626708984375); }
&[data-tn-seat-id="532104"] { transform: matrix(1, 0, 0, 1, -714.7314453125, 725.7183837890625); }
&[data-tn-seat-id="532105"] { transform: matrix(1, 0, 0, 1, -801.0988159179688, 665.9910888671875); }
&[data-tn-seat-id="532106"] { transform: matrix(1, 0, 0, 1, -883.3854370117188, 605.4277954101562); }
&[data-tn-seat-id="532107"] { transform: matrix(1, 0, 0, 1, -961.4909057617188, 544.30126953125); }

&[data-tn-seat-id="532108"] { transform: matrix(1, 0, 0, 1, -1036.460754, 478.901855); }

&[data-tn-seat-id="532109"] { transform: matrix(1, 0, 0, 1, -1105.4909057617188, 413.49908447265625); }
&[data-tn-seat-id="532110"] { transform: matrix(1, 0, 0, 1, -1168.015625, 351.62164306640625); }
&[data-tn-seat-id="532111"] { transform: matrix(1, 0, 0, 1, -1225.6763305664062, 290.66998291015625); }
&[data-tn-seat-id="532112"] { transform: matrix(1, 0, 0, 1, -1279.5858764648438, 230.72210693359375); }
&[data-tn-seat-id="532113"] { transform: matrix(1, 0, 0, 1, -1330.1006469726562, 171.90447998046875); }
&[data-tn-seat-id="532114"] { transform: matrix(1, 0, 0, 1, -1377.4175415039062, 114.35513305664062); }
&[data-tn-seat-id="532115"] { transform: matrix(1, 0, 0, 1, -1421.6727294921875, 58.21746826171875); }
&[data-tn-seat-id="532116"] { transform: matrix(1, 0, 0, 1, -1462.9824829101562, 3.634063720703125); }
&[data-tn-seat-id="532117"] { transform: matrix(1, 0, 0, 1, -1501.456298828125, -49.2550048828125); }
&[data-tn-seat-id="532118"] { transform: matrix(1, 0, 0, 1, -1537.2095336914062, -100.31796264648438); }
&[data-tn-seat-id="532119"] { transform: matrix(1, 0, 0, 1, -1570.364501953125, -149.43362426757812); }
&[data-tn-seat-id="532120"] { transform: matrix(1, 0, 0, 1, -1601.0535278320312, -196.49424743652344); }
&[data-tn-seat-id="532121"] { transform: matrix(1, 0, 0, 1, -1629.4207763671875, -241.41090393066406); }
&[data-tn-seat-id="532122"] { transform: matrix(1, 0, 0, 1, -1655.6175537109375, -284.1125259399414); }

//
&[data-tn-seat-id="532019"] { transform: matrix(1, 0, 0, 1, 191.676513671875, 1326.3702392578125); }
&[data-tn-seat-id="532020"] { transform: matrix(1, 0, 0, 1, 75.14404296875, 1283.623779296875); }
&[data-tn-seat-id="532021"] { transform: matrix(1, 0, 0, 1, -38.5225830078125, 1238.0657958984375); }
&[data-tn-seat-id="532022"] { transform: matrix(1, 0, 0, 1, -149.69989013671875, 1190.3486328125); }
&[data-tn-seat-id="532023"] { transform: matrix(1, 0, 0, 1, -258.38165283203125, 1140.687255859375); }
&[data-tn-seat-id="532024"] { transform: matrix(1, 0, 0, 1, -364.45794677734375, 1089.2034912109375); }
&[data-tn-seat-id="532025"] { transform: matrix(1, 0, 0, 1, -467.76806640625, 1035.9927978515625); }
&[data-tn-seat-id="532026"] { transform: matrix(1, 0, 0, 1, -568.1185302734375, 981.148681640625); }
&[data-tn-seat-id="532027"] { transform: matrix(1, 0, 0, 1, -665.2899780273438, 924.775390625); }
&[data-tn-seat-id="532028"] { transform: matrix(1, 0, 0, 1, -759.0611572265625, 867.004638671875); }
&[data-tn-seat-id="532029"] { transform: matrix(1, 0, 0, 1, -849.2080078125, 807.9957275390625); }
&[data-tn-seat-id="532030"] { transform: matrix(1, 0, 0, 1, -935.5188598632812, 747.9417724609375); }
&[data-tn-seat-id="532031"] { transform: matrix(1, 0, 0, 1, -1017.8167114257812, 687.07177734375); }
&[data-tn-seat-id="532032"] { transform: matrix(1, 0, 0, 1, -1095.9661865234375, 625.644287109375); }

&[data-tn-seat-id="532033"] { transform: matrix(1, 0, 0, 1, -1175.189941, 557.644287);}

&[data-tn-seat-id="532034"] { transform: matrix(1, 0, 0, 1, -1243.0780639648438, 489.84210205078125); }
&[data-tn-seat-id="532035"] { transform: matrix(1, 0, 0, 1, -1311.7742309570312, 428.96917724609375); }
&[data-tn-seat-id="532036"] { transform: matrix(1, 0, 0, 1, -1375.5675048828125, 368.5455322265625); }
&[data-tn-seat-id="532037"] { transform: matrix(1, 0, 0, 1, -1435.4451904296875, 308.7822265625); }
&[data-tn-seat-id="532038"] { transform: matrix(1, 0, 0, 1, -1491.6771850585938, 249.87506103515625); }
&[data-tn-seat-id="532039"] { transform: matrix(1, 0, 0, 1, -1544.385498046875, 192.02581787109375); }
&[data-tn-seat-id="532040"] { transform: matrix(1, 0, 0, 1, -1593.6429443359375, 135.44436645507812); }
&[data-tn-seat-id="532041"] { transform: matrix(1, 0, 0, 1, -1639.5113525390625, 80.34609985351562); }
&[data-tn-seat-id="532042"] { transform: matrix(1, 0, 0, 1, -1682.0616455078125, 26.947906494140625); }
&[data-tn-seat-id="532043"] { transform: matrix(1, 0, 0, 1, -1721.3865356445312, -24.5400390625); }
&[data-tn-seat-id="532044"] { transform: matrix(1, 0, 0, 1, -1757.6036376953125, -73.9185791015625); }
&[data-tn-seat-id="532045"] { transform: matrix(1, 0, 0, 1, -1790.8610229492188, -121.01077270507812); }
&[data-tn-seat-id="532046"] { transform: matrix(1, 0, 0, 1, -1821.3334350585938, -165.66677856445312); }
&[data-tn-seat-id="532047"] { transform: matrix(1, 0, 0, 1, -1849.2185668945312, -207.7695083618164); }

//
&[data-tn-seat-id="531945"] { transform: matrix(1, 0, 0, 1, -69.48394775390625, 1386.56982421875); }
&[data-tn-seat-id="531946"] { transform: matrix(1, 0, 0, 1, -179.2857666015625, 1339.7081298828125); }
&[data-tn-seat-id="531947"] { transform: matrix(1, 0, 0, 1, -286.419921875, 1290.8055419921875); }
&[data-tn-seat-id="531948"] { transform: matrix(1, 0, 0, 1, -391.28875732421875, 1240.3515625); }
&[data-tn-seat-id="531949"] { transform: matrix(1, 0, 0, 1, -493.9117431640625, 1188.5035400390625); }
&[data-tn-seat-id="531950"] { transform: matrix(1, 0, 0, 1, -594.2140502929688, 1135.3533935546875); }
&[data-tn-seat-id="531951"] { transform: matrix(1, 0, 0, 1, -692.0667114257812, 1080.9700927734375); }
&[data-tn-seat-id="531952"] { transform: matrix(1, 0, 0, 1, -787.303466796875, 1025.4193115234375); }
&[data-tn-seat-id="531953"] { transform: matrix(1, 0, 0, 1, -879.7285461425781, 968.775146484375); }
&[data-tn-seat-id="531954"] { transform: matrix(1, 0, 0, 1, -969.1255493164062, 911.129638671875); }
&[data-tn-seat-id="531955"] { transform: matrix(1, 0, 0, 1, -1055.2623291015625, 852.60009765625); }
&[data-tn-seat-id="531956"] { transform: matrix(1, 0, 0, 1, -1137.902587890625, 793.3360595703125); }
&[data-tn-seat-id="531957"] { transform: matrix(1, 0, 0, 1, -1216.810791015625, 733.5231323242188); }
&[data-tn-seat-id="531958"] { transform: matrix(1, 0, 0, 1, -1291.777099609375, 673.3865966796875); }

&[data-tn-seat-id="531959"] { transform: matrix(1, 0, 0, 1, -1365.6652221679688, 610.4428100585938); }
&[data-tn-seat-id="531960"] { transform: matrix(1, 0, 0, 1, -1439.4178771972656, 550.1824340820312); }
&[data-tn-seat-id="531961"] { transform: matrix(1, 0, 0, 1, -1508.2408142089844, 489.96319580078125); }
&[data-tn-seat-id="531962"] { transform: matrix(1, 0, 0, 1, -1573.1897583007812, 430.071533203125); }
&[data-tn-seat-id="531963"] { transform: matrix(1, 0, 0, 1, -1634.5818481445312, 370.70556640625); }
&[data-tn-seat-id="531964"] { transform: matrix(1, 0, 0, 1, -1692.5807189941406, 312.04669189453125); }
&[data-tn-seat-id="531965"] { transform: matrix(1, 0, 0, 1, -1747.2921447753906, 254.27102661132812); }
&[data-tn-seat-id="531966"] { transform: matrix(1, 0, 0, 1, -1798.8062744140625, 197.54998779296875); }
&[data-tn-seat-id="531967"] { transform: matrix(1, 0, 0, 1, -1847.2114868164062, 142.05001831054688); }
&[data-tn-seat-id="531968"] { transform: matrix(1, 0, 0, 1, -1892.6057739257812, 87.9288330078125); }
&[data-tn-seat-id="531969"] { transform: matrix(1, 0, 0, 1, -1935.0990600585938, 35.332763671875); }
&[data-tn-seat-id="531970"] { transform: matrix(1, 0, 0, 1, -1974.8154907226562, -15.60626220703125); }
&[data-tn-seat-id="531971"] { transform: matrix(1, 0, 0, 1, -2011.8956298828125, -64.77592468261719); }
&[data-tn-seat-id="531972"] { transform: matrix(1, 0, 0, 1, -2046.4909057617188, -112.08348083496094); }

//
&[data-tn-seat-id="531870"] { transform: matrix(1, 0, 0, 1, -220.742431640625, 1422.3411865234375); }
&[data-tn-seat-id="531871"] { transform: matrix(1, 0, 0, 1, -329.8448486328125, 1377.2852783203125); }
&[data-tn-seat-id="531872"] { transform: matrix(1, 0, 0, 1, -436.329833984375, 1330.1871337890625); }
&[data-tn-seat-id="531873"] { transform: matrix(1, 0, 0, 1, -540.5626831054688, 1281.51904296875); }
&[data-tn-seat-id="531874"] { transform: matrix(1, 0, 0, 1, -642.53564453125, 1231.427490234375); }
&[data-tn-seat-id="531875"] { transform: matrix(1, 0, 0, 1, -742.1428833007812, 1179.99462890625); }
&[data-tn-seat-id="531876"] { transform: matrix(1, 0, 0, 1, -839.2220458984375, 1127.283447265625); }
&[data-tn-seat-id="531877"] { transform: matrix(1, 0, 0, 1, -933.5672302246094, 1073.357421875); }
&[data-tn-seat-id="531878"] { transform: matrix(1, 0, 0, 1, -1024.9383544921875, 1018.2939453125); }
&[data-tn-seat-id="531879"] { transform: matrix(1, 0, 0, 1, -1113.0679016113281, 962.196533203125); }
&[data-tn-seat-id="531880"] { transform: matrix(1, 0, 0, 1, -1197.6698303222656, 905.2042236328125); }
&[data-tn-seat-id="531881"] { transform: matrix(1, 0, 0, 1, -1278.4513854980469, 847.5006103515625); }
&[data-tn-seat-id="531882"] { transform: matrix(1, 0, 0, 1, -1355.1339111328125, 789.3223876953125); }
&[data-tn-seat-id="531883"] { transform: matrix(1, 0, 0, 1, -1427.4761352539062, 730.958251953125); }

&[data-tn-seat-id="531884"] { transform: matrix(1, 0, 0, 1, -1498.2523803710938, 669.3284912109375); }
&[data-tn-seat-id="531885"] { transform: matrix(1, 0, 0, 1, -1567.4814147949219, 607.6454467773438); }
&[data-tn-seat-id="531886"] { transform: matrix(1, 0, 0, 1, -1633.4082641601562, 546.1921997070312); }
&[data-tn-seat-id="531887"] { transform: matrix(1, 0, 0, 1, -1696.7026062011719, 485.055419921875); }
&[data-tn-seat-id="531888"] { transform: matrix(1, 0, 0, 1, -1757.5283203125, 424.32110595703125); }
&[data-tn-seat-id="531889"] { transform: matrix(1, 0, 0, 1, -1815.9289855957031, 364.08343505859375); }
&[data-tn-seat-id="531890"] { transform: matrix(1, 0, 0, 1, -1871.8965454101562, 304.4474182128906); }
&[data-tn-seat-id="531891"] { transform: matrix(1, 0, 0, 1, -1925.3947448730469, 245.53097534179688); }
&[data-tn-seat-id="531892"] { transform: matrix(1, 0, 0, 1, -1976.3735961914062, 187.46612548828125); }
&[data-tn-seat-id="531893"] { transform: matrix(1, 0, 0, 1, -2024.7713928222656, 130.40194702148438); }
&[data-tn-seat-id="531894"] { transform: matrix(1, 0, 0, 1, -2070.531494140625, 74.5023193359375); }
&[data-tn-seat-id="531895"] { transform: matrix(1, 0, 0, 1, -2113.6007080078125, 19.947235107421875); }
&[data-tn-seat-id="531896"] { transform: matrix(1, 0, 0, 1, -2153.9393310546875, -33.069091796875); }
&[data-tn-seat-id="531897"] { transform: matrix(1, 0, 0, 1, -2191.5255737304688, -84.34119415283203); }

//
&[data-tn-seat-id="531795"] { transform: matrix(1, 0, 0, 1, -370.1127624511719, 1485.740478515625); }
&[data-tn-seat-id="531796"] { transform: matrix(1, 0, 0, 1, -479.1144104003906, 1439.3526611328125); }
&[data-tn-seat-id="531797"] { transform: matrix(1, 0, 0, 1, -585.0760192871094, 1390.684326171875); }
&[data-tn-seat-id="531798"] { transform: matrix(1, 0, 0, 1, -688.3904724121094, 1340.270751953125); }
&[data-tn-seat-id="531799"] { transform: matrix(1, 0, 0, 1, -789.0347595214844, 1288.293701171875); }
&[data-tn-seat-id="531800"] { transform: matrix(1, 0, 0, 1, -886.8733215332031, 1234.866943359375); }
&[data-tn-seat-id="531801"] { transform: matrix(1, 0, 0, 1, -981.707275390625, 1180.0894775390625); }
&[data-tn-seat-id="531802"] { transform: matrix(1, 0, 0, 1, -1073.2971954345703, 1124.0709228515625); }
&[data-tn-seat-id="531803"] { transform: matrix(1, 0, 0, 1, -1161.3721160888672, 1066.945556640625); }
&[data-tn-seat-id="531804"] { transform: matrix(1, 0, 0, 1, -1245.6513977050781, 1008.88720703125); }
&[data-tn-seat-id="531805"] { transform: matrix(1, 0, 0, 1, -1325.8558654785156, 950.11572265625); }
&[data-tn-seat-id="531806"] { transform: matrix(1, 0, 0, 1, -1401.7276611328125, 890.9019775390625); }

&[data-tn-seat-id="531808"] { transform: matrix(1, 0, 0, 1, -1548.8395538330078, 758.1288452148438); }
&[data-tn-seat-id="531809"] { transform: matrix(1, 0, 0, 1, -1621.2147216796875, 699.5487670898438); }
&[data-tn-seat-id="531810"] { transform: matrix(1, 0, 0, 1, -1689.5550231933594, 641.0758056640625); }
&[data-tn-seat-id="531811"] { transform: matrix(1, 0, 0, 1, -1754.041275024414, 582.9737548828125); }
&[data-tn-seat-id="531812"] { transform: matrix(1, 0, 0, 1, -1814.2359924316406, 525.5938110351562); }
&[data-tn-seat-id="531813"] { transform: matrix(1, 0, 0, 1, -1869.4163208007812, 469.486328125); }
&[data-tn-seat-id="531814"] { transform: matrix(1, 0, 0, 1, -1918.6018981933594, 415.55169677734375); }

//
&[data-tn-seat-id="531720"] { transform: matrix(1, 0, 0, 1, -482.5602722167969, 1567.6551513671875); }
&[data-tn-seat-id="531721"] { transform: matrix(1, 0, 0, 1, -594.4145202636719, 1522.0948486328125); }
&[data-tn-seat-id="531722"] { transform: matrix(1, 0, 0, 1, -702.6470947265625, 1473.5880126953125); }
&[data-tn-seat-id="531723"] { transform: matrix(1, 0, 0, 1, -807.70263671875, 1422.8612060546875); }
&[data-tn-seat-id="531724"] { transform: matrix(1, 0, 0, 1, -909.5515747070312, 1370.18359375); }
&[data-tn-seat-id="531725"] { transform: matrix(1, 0, 0, 1, -1008.0487670898438, 1315.743408203125); }
&[data-tn-seat-id="531726"] { transform: matrix(1, 0, 0, 1, -1102.996826171875, 1259.7152099609375); }
&[data-tn-seat-id="531727"] { transform: matrix(1, 0, 0, 1, -1194.177017211914, 1202.2889404296875); }
&[data-tn-seat-id="531728"] { transform: matrix(1, 0, 0, 1, -1281.3720703125, 1143.682861328125); }
&[data-tn-seat-id="531729"] { transform: matrix(1, 0, 0, 1, -1364.382797241211, 1084.149169921875); }
&[data-tn-seat-id="531730"] { transform: matrix(1, 0, 0, 1, -1443.0533447265625, 1023.97509765625); }
&[data-tn-seat-id="531731"] { transform: matrix(1, 0, 0, 1, -1517.2870788574219, 963.4736938476562); }

&[data-tn-seat-id="532430"] { transform: matrix(1, 0, 0, 1, -1543.2271728515625, -483.8529968261719); }
&[data-tn-seat-id="532505"] { transform: matrix(1, 0, 0, 1, -1470.9876708984375, -556.2281951904297); }
&[data-tn-seat-id="532580"] { transform: matrix(1, 0, 0, 1, -1398.747802734375, -628.523681640625); }
&[data-tn-seat-id="532655"] { transform: matrix(1, 0, 0, 1, -1326.5076904296875, -700.7656860351562); }
&[data-tn-seat-id="532730"] { transform: matrix(1, 0, 0, 1, -1254.267578125, -772.9616394042969); }
&[data-tn-seat-id="532805"] { transform: matrix(1, 0, 0, 1, -1182.027099609375, -845.1070404052734); }
&[data-tn-seat-id="532880"] { transform: matrix(1, 0, 0, 1, -1109.786865234375, -917.1933746337891); }
&[data-tn-seat-id="532955"] { transform: matrix(1, 0, 0, 1, -1037.54638671875, -989.2274322509766); }
&[data-tn-seat-id="533030"] { transform: matrix(1, 0, 0, 1, -965.3056640625, -1061.2092742919922); }
&[data-tn-seat-id="533105"] { transform: matrix(1, 0, 0, 1, -893.0654296875, -1133.1390533447266); }
&[data-tn-seat-id="533180"] { transform: matrix(1, 0, 0, 1, -820.824951171875, -1205.0140533447266); }
&[data-tn-seat-id="533255"] { transform: matrix(1, 0, 0, 1, -748.584716796875, -1276.8212585449219); }
&[data-tn-seat-id="533330"] { transform: matrix(1, 0, 0, 1, -676.344482421875, -1348.5699768066406); }
&[data-tn-seat-id="533405"] { transform: matrix(1, 0, 0, 1, -604.10498046875, -1420.2437744140625); }
&[data-tn-seat-id="533480"] { transform: matrix(1, 0, 0, 1, -531.865478515625, -1491.8529968261719); }

//
&[data-tn-seat-id="531720"] { transform: matrix(1, 0, 0, 1, -501.15899658203125, 1587.439697265625); }
&[data-tn-seat-id="531721"] { transform: matrix(1, 0, 0, 1, -611.0663452148438, 1541.453369140625); }
&[data-tn-seat-id="531722"] { transform: matrix(1, 0, 0, 1, -716.3370971679688, 1491.97265625); }
&[data-tn-seat-id="531723"] { transform: matrix(1, 0, 0, 1, -818.0640869140625, 1440.2796630859375); }
&[data-tn-seat-id="531724"] { transform: matrix(1, 0, 0, 1, -916.6393737792969, 1386.8955078125); }
&[data-tn-seat-id="531725"] { transform: matrix(1, 0, 0, 1, -1012.3052062988281, 1332.15771484375); }
&[data-tn-seat-id="531726"] { transform: matrix(1, 0, 0, 1, -1105.2398071289062, 1276.3172607421875); }
&[data-tn-seat-id="531727"] { transform: matrix(1, 0, 0, 1, -1195.5917358398438, 1219.5748291015625); }
&[data-tn-seat-id="531728"] { transform: matrix(1, 0, 0, 1, -1283.4946746826172, 1162.097412109375); }
&[data-tn-seat-id="531729"] { transform: matrix(1, 0, 0, 1, -1369.0667114257812, 1104.02490234375); }
&[data-tn-seat-id="531730"] { transform: matrix(1, 0, 0, 1, -1452.4204406738281, 1045.4776611328125); }
&[data-tn-seat-id="531731"] { transform: matrix(1, 0, 0, 1, -1527.6640625, 986.5602416992188); }

//
//
&[data-tn-seat-id="532431"] { transform: matrix(1, 0, 0, 1, -1538.137939453125, -483.50306701660156); }
&[data-tn-seat-id="532506"] { transform: matrix(1, 0, 0, 1, -1469.2901611328125, -555.8171081542969); }
&[data-tn-seat-id="532581"] { transform: matrix(1, 0, 0, 1, -1400.4422607421875, -628.0770416259766); }
&[data-tn-seat-id="532656"] { transform: matrix(1, 0, 0, 1, -1331.593994140625, -700.28662109375); }
&[data-tn-seat-id="532731"] { transform: matrix(1, 0, 0, 1, -1262.745849609375, -772.4521789550781); }
&[data-tn-seat-id="532806"] { transform: matrix(1, 0, 0, 1, -1193.8974609375, -844.5720672607422); }
&[data-tn-seat-id="532881"] { transform: matrix(1, 0, 0, 1, -1125.049072265625, -916.644287109375); }
&[data-tn-seat-id="532956"] { transform: matrix(1, 0, 0, 1, -1056.20068359375, -988.6663818359375); }
&[data-tn-seat-id="533031"] { transform: matrix(1, 0, 0, 1, -987.352294921875, -1060.6353302001953); }
&[data-tn-seat-id="533106"] { transform: matrix(1, 0, 0, 1, -918.50390625, -1132.5473175048828); }
&[data-tn-seat-id="533181"] { transform: matrix(1, 0, 0, 1, -849.655517578125, -1204.397476196289); }
&[data-tn-seat-id="533256"] { transform: matrix(1, 0, 0, 1, -780.807373046875, -1276.1795196533203); }
&[data-tn-seat-id="533331"] { transform: matrix(1, 0, 0, 1, -711.959716796875, -1347.8888092041016); }
&[data-tn-seat-id="533406"] { transform: matrix(1, 0, 0, 1, -643.1123046875, -1419.5030670166016); }

&[data-tn-seat-id="532431"] { transform: matrix(1, 0, 0, 1, -1613.339111328125, -556.054443359375); }
&[data-tn-seat-id="532506"] { transform: matrix(1, 0, 0, 1, -1541.287841796875, -628.4707641601562); }
&[data-tn-seat-id="532581"] { transform: matrix(1, 0, 0, 1, -1469.236328125, -700.8533782958984); }
&[data-tn-seat-id="532656"] { transform: matrix(1, 0, 0, 1, -1397.1846923828125, -773.2088775634766); }
&[data-tn-seat-id="532731"] { transform: matrix(1, 0, 0, 1, -1325.1328125, -845.54443359375); }
&[data-tn-seat-id="532806"] { transform: matrix(1, 0, 0, 1, -1253.0810546875, -917.8412475585938); }
&[data-tn-seat-id="532881"] { transform: matrix(1, 0, 0, 1, -1181.029052734375, -990.1283569335938); }
&[data-tn-seat-id="532956"] { transform: matrix(1, 0, 0, 1, -1108.976806640625, -1062.3675079345703); }
&[data-tn-seat-id="533031"] { transform: matrix(1, 0, 0, 1, -1036.924560546875, -1134.5952758789062); }
&[data-tn-seat-id="533106"] { transform: matrix(1, 0, 0, 1, -964.87255859375, -1206.7901153564453); }
&[data-tn-seat-id="533181"] { transform: matrix(1, 0, 0, 1, -892.820068359375, -1278.9446716308594); }
&[data-tn-seat-id="533256"] { transform: matrix(1, 0, 0, 1, -820.767578125, -1351.0621032714844); }
&[data-tn-seat-id="533331"] { transform: matrix(1, 0, 0, 1, -748.715087890625, -1423.1385345458984); }
&[data-tn-seat-id="533406"] { transform: matrix(1, 0, 0, 1, -676.66259765625, -1495.1687774658203); }

&[data-tn-seat-id="532433"] { transform: matrix(1, 0, 0, 1, -1762.339111328125, -627.5136108398438); }
&[data-tn-seat-id="532508"] { transform: matrix(1, 0, 0, 1, -1689.517578125, -699.6714897155762); }
&[data-tn-seat-id="532583"] { transform: matrix(1, 0, 0, 1, -1616.69677734375, -772.0261611938477); }
&[data-tn-seat-id="532658"] { transform: matrix(1, 0, 0, 1, -1543.8773193359375, -844.588191986084); }
&[data-tn-seat-id="532733"] { transform: matrix(1, 0, 0, 1, -1471.060302734375, -917.4118957519531); }
&[data-tn-seat-id="532808"] { transform: matrix(1, 0, 0, 1, -1398.2490234375, -990.6279487609863); }
}

.tn-syos-seat-map:has([data-tn-seat-id="532030"]),
.tn-syos-seat-map:has([data-tn-seat-id="532179"]) {
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        overflow: visible;
        pointer-events: none;
        background-repeat: no-repeat;
        z-index: 10;
        opacity: 1;
        background-image: url("../img/circle-stage.svg");
        mix-blend-mode: multiply;
        background-size: 20%;
        background-position: 70% 50%;
    }
    .tn-syos-seat-map__non-seat {
        visibility: hidden;
    }
}

// Add styling for Christmas Carol 2025 production, Baylis Circle
#syos-seat-map-23164-7 {
  .tn-syos-seat-map__svg {
    width: 3558px !important;
  }
}

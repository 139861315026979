// Shared styles for calendar and list views
.tn-event-listing__primary-views-container {
    padding: 0;

    // Wrapper for button icons
    .tn-btn-datepicker__icon-container {
        color: currentColor;
    }

    // Date heading
    .tn-event-listing-view__results-heading {
        @include font-size-base-plus-3;
        color: currentColor;
        margin: $padding-large-vertical 0 $padding-large-vertical 0;
        padding: 0;
    }

    // "to" string in the date range picker
    .tn-events-list-view__datepicker-divider {
        align-items: center;

        &-label {
            @include font-size-base;
            color: currentColor;
            font-style: normal;
            padding: $padding-small-vertical 0;
        }
    }

    // list/calendar and day/week/month view tabs
    .tn-event-listing-mode-tab-nav {
        &__list {
            border-color: $medium-grey;
            border-width: 2px;
            margin-bottom: $padding-base-vertical;

            &-item {
                @include heading-treatment;
                @include font-size-base-minus-2;
                background-color: $medium-grey;
                border-color: $medium-grey;
                color: $black;
                border-top-left-radius: $border-radius-small;
                border-top-right-radius: $border-radius-small;
                padding: $padding-base-vertical $padding-base-horizontal;
                text-align: center;

                &:hover,
                &:active,
                &:focus {
                    background-color: lighten($medium-grey, 2.5%);
                    border-color: $medium-grey;
                }

                &,
                &.active {
                    border-width: 2px;
                }

                &.active {
                    border-color: $medium-grey;
                    margin-bottom: -2px;
                    background-color: $white;
                    color: $black;
                }
            }
        }
    }

    .tn-event-listing-view__no-results-content {
        border: none;
        padding: $padding-small-vertical 0;
        text-align: left;
        color: $white;
        background-color: transparent;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
    }
}

// Calendar view
.tn-events-calendar-view {
    // Filter by date box
    .tn-btn-datepicker {
        border: none;
        max-width: 100% !important;
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;

        &__btn-container {
            max-width: 430px;
        }
    }

    // dayy/week/month tabs
    .tn-event-listing-mode-tab-nav__list {
        position: static;
        margin-bottom: $padding-large-vertical;
    }

    // Main picker
    .tn-btn-datepicker__btn {
        @include button-size($padding-small-vertical, $padding-base-vertical);
        box-shadow: none !important;
        white-space: normal;
    }

    // prev/next arrows for the picker
    .tn-btn-datepicker__btn-period-prev-next {
        @include button-size($padding-small-vertical, $padding-base-vertical);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;

        .glyphicon {
            left: 0;
            top: -1px;
        }
    }

    .tn-btn-datepicker__btn-period-prev-next--btn-next {
        border-radius: 0 $border-radius-base $border-radius-base 0;
        margin-left: 2px;
        padding-left: $spacer-s;
    }

    .tn-btn-datepicker__btn-period-prev-next--btn-prev {
        border-radius: $border-radius-base 0 0 $border-radius-base;
        margin-right: 2px;
        padding-right: $spacer-s;
    }

    .tn-events-calendar {
        &__table {
            border-spacing: 0;
        }

        &__day {
            padding: $padding-small-horizontal;
        }

        &__day-number {
            color: $black;
            padding: 0 0 $padding-small-horizontal 0;
            font-weight: $font-weight-bold;

            &--today {
                &::after {
                    background-color: $blue;
                    margin: 0;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &__day-cell {
            background-color: $white;
            padding: 0 0 $padding-base-vertical 0;

            &.tn-events-calendar__day-cell--past,
            &.tn-events-calendar__day-cell--previous-month,
            &.tn-events-calendar__day-cell--next-month {
                background-color: $light-grey;
            }

        }

        &__event {
            padding: 6px !important;
            border-radius: $border-radius-small;
            max-width: 100%;
            width: 100%;
            white-space: normal;
            text-align: left;
            flex-direction: column-reverse;
            align-items: flex-start;

            &:hover,
            &:active,
            &:focus {
                transform: translateY(-1px);
            }

            .tn-events-calendar__event-name {
                @include font-size-base-minus-1;
                line-height: 1.15 !important;
                padding: 0;
                text-align: left;
            }

            .tn-events-calendar__event-time {
                @include copy-treatment;
                @include font-size-base-minus-1;
                position: static;
                opacity: .75;
            }

            .tn-events-calendar__event-status {
                font-family: $font-family-copy;
                font-size: 75%;
                line-height: 1;
                margin: 0;
                padding: $padding-xs-vertical 0;
                border-radius: $border-radius-tiny;
                background-color: $black;
                font-style: normal;
            }

            .tn-product-type-name {
                @include font-size-base-minus-2;
                font-weight: $font-weight-regular;
                border: none;
                color: currentColor;
                background-color: $light-grey;
                border-radius: 0;
                color: $black;
                font-family: $font-family-copy;
                margin-bottom: $spacer-xs;
            }
        }
        
        &__table {
            border-collapse: collapse;

            th {
                @include heading-treatment;
                @include font-size-base;
                color: $black;
                background-color: $light-grey;
                padding: $padding-base-vertical;
                text-align: center;
            }

            th, td {
                border: 1px solid $medium-grey;
            }

            &-container {
                max-width: 100%;
                overflow-x: scroll;
            }
        }

        &__list-day-heading {
            @include font-size-base-plus-3;
            background-color: transparent;
            color: currentColor;
            padding: 0;
        }
    }
}

// Filter by date box
.tn-event-listing-view__controls-container {
    border: none;
    max-width: 100% !important;
    border-radius: $border-radius-large;
    padding: 0;
    margin-bottom: $padding-large-vertical;
}

// List view
.tn-events-list-view {
    &__date-range {
        align-items: center;

        @include mq($from: tablet, $until: desktop) {
            flex-direction: column;

            > div {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    .tn-btn-datepicker__btn {
        @include button-size($padding-small-vertical, $padding-base-vertical);
    }

    &__btn-reset-dates {
        @include button-variant($btn-tertiary-color, $btn-tertiary-bg, $btn-tertiary-bg-hover);
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include mq($from: tablet, $until: desktop) {
            margin-top: $padding-large-vertical;
        }

        @include mq($from: desktop) {
            width: auto;
        }

        &-container {
            @include mq($from: tablet, $until: desktop) {
                padding-left: 0 !important;
            }
        }
    }

    // A single event
    .tn-prod-list-item {
        background: transparent;
        border: none;
        padding: $padding-large-vertical 0 0 0;
        border-top: 1px solid $white;
        margin-bottom: $padding-large-vertical;
        display: flex;
        flex-direction: column;

        &__property {
            margin-top: 0;

            &--heading {
                @include font-size-base-plus-4;
                margin-bottom: $spacer-s;

                p {
                    display: inline;
                }
            }
            
            &--datetime {
                font-weight: bold;
                opacity: .75;
                font-style: italic;
            }

            &--img-container {
                display: none;
            }
        }

        // Instance
        &__perf {
            &-list-item {
                padding: 0;
                margin-bottom: 0;

                & + .tn-prod-list-item__perf-list-item {
                    border-top: 1px solid $medium-grey;
                }
            }

            // Instance link
            &-anchor {
                background-color: transparent;
                border: none;
                color: currentColor;
                gap: 0 $padding-small-horizontal;
                padding-left: 0;
                justify-content: flex-start;
                padding: $spacer-s 2em $spacer-s 0;
                flex-direction: column;
                align-items: flex-start;
                border-radius: $border-radius-base;
                text-decoration: none;

                @include mq($from: tablet) {
                    flex-direction: row;
                    align-items: center;
                    gap: $padding-small-vertical $padding-large-vertical;
                    padding-right: 0;
                }

                .glyphicon {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    transform: none;
                    color: inherit;
                    justify-content: flex-end;

                    @include mq($from: tablet) {
                        position: static;
                    }

                    &::before {
                        content: "\F061";
                        font-family: 'FontAwesome';
                        position: static;
                        transform: none;
                        top: 1.35em;
                        font-weight: $font-weight-regular;
                    }
                }
            }

            &-status {
                background-color: $orange;
                @include font-size-base-minus-2;
                padding: 3px 6px;
                text-transform: uppercase;
                font-style: normal;
            }

            &-property {
                padding: 0;
                width: auto;

                &--datetime {
                    background-color: transparent;
                    font-weight: bold;
                    color: currentColor;
                    @include font-size-base;
                    flex: auto;
                    width: auto;
                    display: flex;
                    gap: 1ch;
                    text-align: left;

                    @include mq($from: tablet) {
                        flex: 0 1 275px;
                    }
                }

                &--action {
                    font-weight: bold;
                    display: none;
                    flex-grow: 1;

                    @include mq($from: desktop) {
                        display: block;
                    }
                }

                p:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__button {
            color: currentColor;
            padding: 0;
            background-color: transparent;
            border: none;
        }
    }

    .tn-product-type-name {
        @include font-size-base-minus-2;
        font-weight: $font-weight-regular;
        border: none;
        color: currentColor;
        background-color: $light-grey;
        border-radius: 0;
        color: $black;
        font-family: $font-family-copy;
        
        @include mq($from: tablet) {
            margin-bottom: $spacer-xs;
        }
    }
}

// Event filters
.tn-events-listing-page {
    .tn-event-listing__controls-heading {
        @include font-size-base-plus-2;
    }

    div.tn-events-keyword-filter {
        border-radius: $border-radius-large;
        border: none;
        padding: $spacer-s;
        margin-bottom: $spacer-m;

        @include mq($from: desktop) {
            padding: $spacer-m;
        }

        .tn-events-keyword-filter__keyword-check-icon {
            color: inherit;
            opacity: .25;
        }

        .tn-events-keyword-filter__keyword-button {
            gap: .5em;
            white-space: normal;
        }

        .tn-events-keyword-filter__keyword-input+.tn-events-keyword-filter__keyword-button {
            @include button-variant($dark-grey, $white, $light-bg);
        }

        .tn-events-keyword-filter__keyword-input:checked+.tn-events-keyword-filter__keyword-button {
            @include button-variant($white, $black, $dark-grey);
        }
    }

    .tn-events-keyword-filter__list {
        display: flex;
        flex-wrap: wrap;
        gap: $padding-base-vertical;

        label {
            margin-bottom: 0;
        }
    }
}
.tn-syos-seat-map__seat, .twc-icon {

  // Row F
  &[data-tn-seat-id="528345"] { transform: matrix(1, 0, 0, 1, 973.05029296875, -185.4696044921875); }
  &[data-tn-seat-id="528346"] { transform: matrix(1, 0, 0, 1, 930.119873046875, -244.7442626953125); }
  &[data-tn-seat-id="528347"] { transform: matrix(1, 0, 0, 1, 880.6826171875, -306.8082275390625); }
  &[data-tn-seat-id="528348"] { transform: matrix(1, 0, 0, 1, 824.97119140625, -370.80419921875); }
  &[data-tn-seat-id="528349"] { transform: matrix(1, 0, 0, 1, 762.434814453125, -436.1539306640625); }
  &[data-tn-seat-id="528350"] { transform: matrix(1, 0, 0, 1, 692.34521484375, -502.15118408203125); }
  &[data-tn-seat-id="528351"] { transform: matrix(1, 0, 0, 1, 614.02490234375, -567.8662719726562); }
  &[data-tn-seat-id="528352"] { transform: matrix(1, 0, 0, 1, 527.065673828125, -632.1630859375); }
  &[data-tn-seat-id="528353"] { transform: matrix(1, 0, 0, 1, 431.515625, -693.8331909179688); }
  &[data-tn-seat-id="528354"] { transform: matrix(1, 0, 0, 1, 327.953125, -751.8173828125); }

  // Row G
  &[data-tn-seat-id="528419"] { transform: matrix(1, 0, 0, 1, 1128.748291015625, -206); }
  &[data-tn-seat-id="528420"] { transform: matrix(1, 0, 0, 1, 1087.305908203125, -262.9139404296875); }
  &[data-tn-seat-id="528421"] { transform: matrix(1, 0, 0, 1, 1040.122314453125, -322.5748291015625); }
  &[data-tn-seat-id="528422"] { transform: matrix(1, 0, 0, 1, 987.605224609375, -384.1844482421875); }
  &[data-tn-seat-id="528423"] { transform: matrix(1, 0, 0, 1, 929.4619140625, -447.295654296875); }
  &[data-tn-seat-id="528424"] { transform: matrix(1, 0, 0, 1, 865.23486328125, -511.4378662109375); }
  &[data-tn-seat-id="528425"] { transform: matrix(1, 0, 0, 1, 794.44677734375, -576.0343017578125); }
  &[data-tn-seat-id="528426"] { transform: matrix(1, 0, 0, 1, 716.716796875, -640.384521484375); }
  &[data-tn-seat-id="528427"] { transform: matrix(1, 0, 0, 1, 631.8564453125, -703.6950073242188); }
  &[data-tn-seat-id="528428"] { transform: matrix(1, 0, 0, 1, 539.94970703125, -765.1553344726562); }
  &[data-tn-seat-id="528429"] { transform: matrix(1, 0, 0, 1, 441.375732421875, -824.0428466796875); }
  &[data-tn-seat-id="528430"] { transform: matrix(1, 0, 0, 1, 336.748291015625, -879.8087158203125); }

  // Row H
  &[data-tn-seat-id="528494"] { transform: matrix(1, 0, 0, 1, 1235.363525390625, -222.6878662109375); } // H29
  &[data-tn-seat-id="528495"] { transform: matrix(1, 0, 0, 1, 1192.095458984375, -285.278076171875); } // H30
  &[data-tn-seat-id="528496"] { transform: matrix(1, 0, 0, 1, 1143.030029296875, -349.7869873046875); } // H31
  &[data-tn-seat-id="528497"] { transform: matrix(1, 0, 0, 1, 1088.560791015625, -415.5889892578125); } // H32
  &[data-tn-seat-id="528498"] { transform: matrix(1, 0, 0, 1, 1030.521484375, -482.369384765625); } // H33
  &[data-tn-seat-id="528499"] { transform: matrix(1, 0, 0, 1, 966.688720703125, -549.8463134765625); } // H34
  &[data-tn-seat-id="528500"] { transform: matrix(1, 0, 0, 1, 899.82275390625, -617.7161254882812); } // H35
  &[data-tn-seat-id="528501"] { transform: matrix(1, 0, 0, 1, 826.704833984375, -685.6326904296875); } // H36
  &[data-tn-seat-id="528502"] { transform: matrix(1, 0, 0, 1, 749.183837890625, -753.1995239257812); } // H37
  &[data-tn-seat-id="528503"] { transform: matrix(1, 0, 0, 1, 667.19921875, -819.9752807617188); } // H38
  &[data-tn-seat-id="528504"] { transform: matrix(1, 0, 0, 1, 581.80322265625, -885.4901733398438); } // H39
  &[data-tn-seat-id="528505"] { transform: matrix(1, 0, 0, 1, 490.173828125, -949.273193359375); } // H40
  &[data-tn-seat-id="528439"] { transform: matrix(1, 0, 0, 1, 395.363525390625, -1010.8853759765625); } // H41


  // Row J
  &[data-tn-seat-id="528569"] { transform: matrix(1, 0, 0, 1, 1356.464111328125, -271.5826416015625); }
  &[data-tn-seat-id="528570"] { transform: matrix(1, 0, 0, 1, 1312.529052734375, -331.70947265625); }
  &[data-tn-seat-id="528571"] { transform: matrix(1, 0, 0, 1, 1263.060302734375, -394.142822265625); }
  &[data-tn-seat-id="528572"] { transform: matrix(1, 0, 0, 1, 1208.917724609375, -458.07275390625); }
  &[data-tn-seat-id="528573"] { transform: matrix(1, 0, 0, 1, 1150.232666015625, -523.1015625); }
  &[data-tn-seat-id="528574"] { transform: matrix(1, 0, 0, 1, 1086.99072265625, -588.899169921875); }
  &[data-tn-seat-id="528575"] { transform: matrix(1, 0, 0, 1, 1019.14208984375, -655.1468505859375); }
  &[data-tn-seat-id="528576"] { transform: matrix(1, 0, 0, 1, 946.640380859375, -721.5225219726562); }
  &[data-tn-seat-id="528577"] { transform: matrix(1, 0, 0, 1, 869.470947265625, -787.6983642578125); }
  &[data-tn-seat-id="528578"] { transform: matrix(1, 0, 0, 1, 787.671142578125, -853.3485717773438); }
  &[data-tn-seat-id="528579"] { transform: matrix(1, 0, 0, 1, 701.330810546875, -918.158935546875); }
  &[data-tn-seat-id="528580"] { transform: matrix(1, 0, 0, 1, 610.600830078125, -981.8401489257812); }
  &[data-tn-seat-id="528581"] { transform: matrix(1, 0, 0, 1, 515.68701171875, -1044.13916015625); }

  // Row K
  &[data-tn-seat-id="528643"] { transform: matrix(1, 0, 0, 1, 1532.967529296875, -302.9217529296875); }
  &[data-tn-seat-id="528644"] { transform: matrix(1, 0, 0, 1, 1487.037109375, -364.887939453125); }
  &[data-tn-seat-id="528645"] { transform: matrix(1, 0, 0, 1, 1436.65087890625, -428.5589599609375); }
  &[data-tn-seat-id="528646"] { transform: matrix(1, 0, 0, 1, 1382.506591796875, -493.3858642578125); }
  &[data-tn-seat-id="528647"] { transform: matrix(1, 0, 0, 1, 1324.685791015625, -559.1151123046875); }
  &[data-tn-seat-id="528648"] { transform: matrix(1, 0, 0, 1, 1263.122802734375, -625.541015625); }
  &[data-tn-seat-id="528649"] { transform: matrix(1, 0, 0, 1, 1197.686279296875, -692.460205078125); }
  &[data-tn-seat-id="528650"] { transform: matrix(1, 0, 0, 1, 1128.22314453125, -759.65185546875); }
  &[data-tn-seat-id="528651"] { transform: matrix(1, 0, 0, 1, 1054.5712890625, -826.8701782226562); }
  &[data-tn-seat-id="528652"] { transform: matrix(1, 0, 0, 1, 976.586669921875, -893.8409423828125); }
  &[data-tn-seat-id="528653"] { transform: matrix(1, 0, 0, 1, 894.15185546875, -960.263427734375); }
  &[data-tn-seat-id="528654"] { transform: matrix(1, 0, 0, 1, 807.2060546875, -1025.8177490234375); }
  &[data-tn-seat-id="528655"] { transform: matrix(1, 0, 0, 1, 715.73974609375, -1090.1744995117188); }
  &[data-tn-seat-id="528656"] { transform: matrix(1, 0, 0, 1, 619.81982421875, -1153.0120849609375); }
  &[data-tn-seat-id="528657"] { transform: matrix(1, 0, 0, 1, 519.5830078125, -1214.031982421875); }
  &[data-tn-seat-id="528658"] { transform: matrix(1, 0, 0, 1, 415.230224609375, -1272.9739074707031); }

  // Row L
  &[data-tn-seat-id="528717"] { transform: matrix(1, 0, 0, 1, 1689.151123046875, -299.40869140625); }
  &[data-tn-seat-id="528718"] { transform: matrix(1, 0, 0, 1, 1647.33837890625, -357.3399658203125); }
  &[data-tn-seat-id="528719"] { transform: matrix(1, 0, 0, 1, 1601.54833984375, -417.1884765625); }
  &[data-tn-seat-id="528720"] { transform: matrix(1, 0, 0, 1, 1552.39794921875, -478.4002685546875); }
  &[data-tn-seat-id="528721"] { transform: matrix(1, 0, 0, 1, 1499.952880859375, -540.742919921875); }
  &[data-tn-seat-id="528722"] { transform: matrix(1, 0, 0, 1, 1444.14453125, -604.042236328125); }
  &[data-tn-seat-id="528723"] { transform: matrix(1, 0, 0, 1, 1384.837158203125, -668.1343994140625); }
  &[data-tn-seat-id="528724"] { transform: matrix(1, 0, 0, 1, 1321.87158203125, -732.8428955078125); }
  &[data-tn-seat-id="528725"] { transform: matrix(1, 0, 0, 1, 1255.073486328125, -797.9708251953125); }
  &[data-tn-seat-id="528726"] { transform: matrix(1, 0, 0, 1, 1184.2685546875, -863.29443359375); }
  &[data-tn-seat-id="528727"] { transform: matrix(1, 0, 0, 1, 1109.305419921875, -928.5609741210938); }
  &[data-tn-seat-id="528728"] { transform: matrix(1, 0, 0, 1, 1030.063720703125, -993.4915161132812); }
  &[data-tn-seat-id="528729"] { transform: matrix(1, 0, 0, 1, 946.471923828125, -1057.78759765625); }
  &[data-tn-seat-id="528730"] { transform: matrix(1, 0, 0, 1, 858.5185546875, -1121.142333984375); }
  &[data-tn-seat-id="528731"] { transform: matrix(1, 0, 0, 1, 766.25927734375, -1183.2542724609375); }
  &[data-tn-seat-id="528732"] { transform: matrix(1, 0, 0, 1, 669.813232421875, -1243.8412475585938); }
  &[data-tn-seat-id="528733"] { transform: matrix(1, 0, 0, 1, 569.365966796875, -1302.6558837890625); }
  &[data-tn-seat-id="528734"] { transform: matrix(1, 0, 0, 1, 465.151123046875, -1359.4956665039062); }

  // Row M
  &[data-tn-seat-id="528790"] { transform: matrix(1, 0, 0, 1, 1928.140380859375, -292.9913330078125); }
  &[data-tn-seat-id="528791"] { transform: matrix(1, 0, 0, 1, 1882.5693359375, -350.817138671875); }
  &[data-tn-seat-id="528792"] { transform: matrix(1, 0, 0, 1, 1833.9775390625, -409.937744140625); }
  &[data-tn-seat-id="528793"] { transform: matrix(1, 0, 0, 1, 1782.918212890625, -469.977783203125); }
  &[data-tn-seat-id="528794"] { transform: matrix(1, 0, 0, 1, 1729.49951171875, -530.78955078125); }
  &[data-tn-seat-id="528795"] { transform: matrix(1, 0, 0, 1, 1673.718994140625, -592.2735595703125); }
  &[data-tn-seat-id="528796"] { transform: matrix(1, 0, 0, 1, 1615.52001953125, -654.3447265625); }
  &[data-tn-seat-id="528797"] { transform: matrix(1, 0, 0, 1, 1554.81787109375, -716.919921875); }
  &[data-tn-seat-id="528798"] { transform: matrix(1, 0, 0, 1, 1491.50146484375, -779.91064453125); }
  &[data-tn-seat-id="528799"] { transform: matrix(1, 0, 0, 1, 1425.4453125, -843.21826171875); }
  &[data-tn-seat-id="528800"] { transform: matrix(1, 0, 0, 1, 1356.522705078125, -906.729248046875); }
  &[data-tn-seat-id="528801"] { transform: matrix(1, 0, 0, 1, 1284.590087890625, -970.314208984375); }
  &[data-tn-seat-id="528802"] { transform: matrix(1, 0, 0, 1, 1209.5087890625, -1033.8237915039062); }
  &[data-tn-seat-id="528803"] { transform: matrix(1, 0, 0, 1, 1131.15869140625, -1097.0897216796875); }
  &[data-tn-seat-id="528804"] { transform: matrix(1, 0, 0, 1, 1049.4189453125, -1159.9225463867188); }
  &[data-tn-seat-id="528805"] { transform: matrix(1, 0, 0, 1, 964.2001953125, -1222.1161499023438); }
  &[data-tn-seat-id="528806"] { transform: matrix(1, 0, 0, 1, 875.44384765625, -1283.4507446289062); }
  &[data-tn-seat-id="528807"] { transform: matrix(1, 0, 0, 1, 783.126708984375, -1343.6991577148438); }
  &[data-tn-seat-id="528808"] { transform: matrix(1, 0, 0, 1, 687.269775390625, -1402.6355285644531); }
  &[data-tn-seat-id="528809"] { transform: matrix(1, 0, 0, 1, 587.936767578125, -1460.0423889160156); }
  &[data-tn-seat-id="528810"] { transform: matrix(1, 0, 0, 1, 485.2373046875, -1515.7217407226562); }

  // Row N
  &[data-tn-seat-id="528862"] { transform: matrix(1, 0, 0, 1, 2177.87060546875, -213.9652099609375); }
  &[data-tn-seat-id="528863"] { transform: matrix(1, 0, 0, 1, 2136.33984375, -267.93505859375); }
  &[data-tn-seat-id="528864"] { transform: matrix(1, 0, 0, 1, 2092.163330078125, -323.3226318359375); }
  &[data-tn-seat-id="528865"] { transform: matrix(1, 0, 0, 1, 2045.849853515625, -379.733642578125); }
  &[data-tn-seat-id="528866"] { transform: matrix(1, 0, 0, 1, 1997.51611328125, -437.022216796875); }
  &[data-tn-seat-id="528867"] { transform: matrix(1, 0, 0, 1, 1947.177490234375, -495.098388671875); }
  &[data-tn-seat-id="528868"] { transform: matrix(1, 0, 0, 1, 1894.80517578125, -553.8929443359375); }
  &[data-tn-seat-id="528869"] { transform: matrix(1, 0, 0, 1, 1840.34912109375, -613.3424072265625); }
  &[data-tn-seat-id="528870"] { transform: matrix(1, 0, 0, 1, 1783.728515625, -673.38818359375); }
  &[data-tn-seat-id="528871"] { transform: matrix(1, 0, 0, 1, 1724.86328125, -733.964111328125); }
  &[data-tn-seat-id="528872"] { transform: matrix(1, 0, 0, 1, 1663.65478515625, -795.0009765625); }
  &[data-tn-seat-id="528873"] { transform: matrix(1, 0, 0, 1, 1600.000732421875, -856.41943359375); }
  &[data-tn-seat-id="528874"] { transform: matrix(1, 0, 0, 1, 1533.79736328125, -918.130615234375); }
  &[data-tn-seat-id="528875"] { transform: matrix(1, 0, 0, 1, 1464.9345703125, -980.0336303710938); }
  &[data-tn-seat-id="528876"] { transform: matrix(1, 0, 0, 1, 1393.3125, -1042.0145874023438); }
  &[data-tn-seat-id="528877"] { transform: matrix(1, 0, 0, 1, 1318.835693359375, -1103.9468994140625); }
  &[data-tn-seat-id="528878"] { transform: matrix(1, 0, 0, 1, 1241.422119140625, -1165.6914672851562); }
  &[data-tn-seat-id="528879"] { transform: matrix(1, 0, 0, 1, 1161.003173828125, -1227.0983276367188); }
  &[data-tn-seat-id="528880"] { transform: matrix(1, 0, 0, 1, 1077.535400390625, -1288.0090942382812); }
  &[data-tn-seat-id="528881"] { transform: matrix(1, 0, 0, 1, 990.993896484375, -1348.2597045898438); }
  &[data-tn-seat-id="528882"] { transform: matrix(1, 0, 0, 1, 901.38427734375, -1407.6860961914062); }
  &[data-tn-seat-id="528883"] { transform: matrix(1, 0, 0, 1, 808.74072265625, -1466.1279907226562); }
  &[data-tn-seat-id="528884"] { transform: matrix(1, 0, 0, 1, 713.119873046875, -1523.4313049316406); }
  &[data-tn-seat-id="528885"] { transform: matrix(1, 0, 0, 1, 614.61279296875, -1579.4580688476562); }
  &[data-tn-seat-id="528886"] { transform: matrix(1, 0, 0, 1, 513.328125, -1634.0846862792969); }
  &[data-tn-seat-id="528887"] { transform: matrix(1, 0, 0, 1, 409.3984375, -1687.2088317871094); }
  &[data-tn-seat-id="528888"] { transform: matrix(1, 0, 0, 1, 302.967529296875, -1738.7478332519531); }

  // Row C
  &[data-tn-seat-id="527442"] { transform: matrix(1, 0, 0, 1, 960.8848876953125, 733.6434326171875); }
  &[data-tn-seat-id="527517"] { transform: matrix(1, 0, 0, 1, 1020.47998046875, 689.7471923828125); }
  &[data-tn-seat-id="527592"] { transform: matrix(1, 0, 0, 1, 1082.8057861328125, 639.017333984375); }

  &[data-tn-seat-id="527817"] { transform: matrix(1, 0, 0, 1, 1209.806396484375, 442.5616455078125); }
  &[data-tn-seat-id="527892"] { transform: matrix(1, 0, 0, 1, 1275.36767578125, 364.254150390625); }
  &[data-tn-seat-id="527967"] { transform: matrix(1, 0, 0, 1, 1339.99755859375, 279.4962158203125); }
  &[data-tn-seat-id="528042"] { transform: matrix(1, 0, 0, 1, 1403.0970458984375, 188.5833740234375); }
  &[data-tn-seat-id="528117"] { transform: matrix(1, 0, 0, 1, 1463.7794189453125, 90.9769287109375); }
  &[data-tn-seat-id="528192"] { transform: matrix(1, 0, 0, 1, 1520.75927734375, -14.03076171875); }
  &[data-tn-seat-id="528193"] { transform: matrix(1, 0, 0, 1, 1572.292236328125, -126.9984130859375); }



  // Row D
  &[data-tn-seat-id="527365"] { transform: matrix(1, 0, 0, 1, 1025.0474853515625, 894.777099609375); }
  &[data-tn-seat-id="527440"] { transform: matrix(1, 0, 0, 1, 1079.51220703125, 857.060302734375); }
  &[data-tn-seat-id="527515"] { transform: matrix(1, 0, 0, 1, 1138.1695556640625, 811.52734375); }
  &[data-tn-seat-id="527516"] { transform: matrix(1, 0, 0, 1, 1200.482177734375, 755.363037109375); }

  &[data-tn-seat-id="527740"] { transform: matrix(1, 0, 0, 1, 1321.2608642578125, 623.70068359375); }
  &[data-tn-seat-id="527815"] { transform: matrix(1, 0, 0, 1, 1385.5506591796875, 556.470947265625); }
  &[data-tn-seat-id="527890"] { transform: matrix(1, 0, 0, 1, 1449.9239501953125, 480.2611083984375); }
  &[data-tn-seat-id="527965"] { transform: matrix(1, 0, 0, 1, 1513.394775390625, 396.68896484375); }
  &[data-tn-seat-id="528040"] { transform: matrix(1, 0, 0, 1, 1575.2559814453125, 306.3575439453125); }
  &[data-tn-seat-id="528115"] { transform: matrix(1, 0, 0, 1, 1634.911865234375, 209.773681640625); }
  &[data-tn-seat-id="528190"] { transform: matrix(1, 0, 0, 1, 1691.88330078125, 107.478759765625); }
  &[data-tn-seat-id="528265"] { transform: matrix(1, 0, 0, 1, 1745.825927734375, 0.0648193359375); }
  &[data-tn-seat-id="528266"] { transform: matrix(1, 0, 0, 1, 1796.529541015625, -111.853515625); }

  // Row E
  &[data-tn-seat-id="527289"] { transform: matrix(1, 0, 0, 1, 1089.3043212890625, 982.3184814453125); }
  &[data-tn-seat-id="527364"] { transform: matrix(1, 0, 0, 1, 1152.24169921875, 937.9610595703125); }
  &[data-tn-seat-id="527513"] { transform: matrix(1, 0, 0, 1, 1216.01025390625, 891.62890625); }
  &[data-tn-seat-id="527588"] { transform: matrix(1, 0, 0, 1, 1280.687744140625, 842.9044189453125); }

  &[data-tn-seat-id="527738"] { transform: matrix(1, 0, 0, 1, 1430.122559, 736.38855); }
  &[data-tn-seat-id="527739"] { transform: matrix(1, 0, 0, 1, 1493.7403564453125, 671.787841796875); }

  &[data-tn-seat-id="527963"] { transform: matrix(1, 0, 0, 1, 1702.6798095703125, 501.6241455078125); }
  &[data-tn-seat-id="528038"] { transform: matrix(1, 0, 0, 1, 1763.8175048828125, 419.32373046875); }
  &[data-tn-seat-id="528113"] { transform: matrix(1, 0, 0, 1, 1823.889404296875, 332.0955810546875); }

  //  Row F
  &[data-tn-seat-id="527736"] { transform: matrix(1, 0, 0, 1, 1573.9892578125, 843.5391845703125); }
  &[data-tn-seat-id="527811"] { transform: matrix(1, 0, 0, 1, 1637.7686767578125, 777.4622802734375); }
  &[data-tn-seat-id="527886"] { transform: matrix(1, 0, 0, 1, 1701.7158203125, 702.4434814453125); }

  &[data-tn-seat-id="528036"] { transform: matrix(1, 0, 0, 1, 1848.4901123046875, 536.7388916015625); }
  &[data-tn-seat-id="528111"] { transform: matrix(1, 0, 0, 1, 1910.832275390625, 452.6651611328125); }
  &[data-tn-seat-id="528112"] { transform: matrix(1, 0, 0, 1, 1972.889404296875, 367.210205078125); }

  // Row F
  &[data-tn-seat-id="527219"] { transform: matrix(1, 0, 0, 1, 122.740966796875, 696.7130126953125); }
  &[data-tn-seat-id="527220"] { transform: matrix(1, 0, 0, 1, 7.3253173828125, 645.084228515625); }
  &[data-tn-seat-id="527221"] { transform: matrix(1, 0, 0, 1, -95.2540283203125, 588.7313232421875); }
  &[data-tn-seat-id="527222"] { transform: matrix(1, 0, 0, 1, -193.13037109375, 530.05908203125); }
  &[data-tn-seat-id="527223"] { transform: matrix(1, 0, 0, 1, -286.3865966796875, 469.5615234375); }
  &[data-tn-seat-id="527224"] { transform: matrix(1, 0, 0, 1, -374.421142578125, 407.48046875); }


  &[data-tn-seat-id="527227"] { transform: matrix(1, 0, 0, 1, -586.946044921875, 217.67828369140625); }
  &[data-tn-seat-id="527228"] { transform: matrix(1, 0, 0, 1, -642.513916015625, 156.8348388671875); }
  &[data-tn-seat-id="527229"] { transform: matrix(1, 0, 0, 1, -694.246826171875, 97.1519775390625); }
  &[data-tn-seat-id="527230"] { transform: matrix(1, 0, 0, 1, -742.5306396484375, 38.75445556640625); }
  &[data-tn-seat-id="527231"] { transform: matrix(1, 0, 0, 1, -787.204345703125, -18.041015625); }
  &[data-tn-seat-id="527232"] { transform: matrix(1, 0, 0, 1, -927.9281005859375, -72.75653076171875); }

  // Row G
  &[data-tn-seat-id="527143"] { transform: matrix(1, 0, 0, 1, 80.7230224609375, 817.1304931640625); }
  &[data-tn-seat-id="527144"] { transform: matrix(1, 0, 0, 1, -22.89990234375, 767.103271484375); }
  &[data-tn-seat-id="527145"] { transform: matrix(1, 0, 0, 1, -111.420166015625, 736.4742431640625); }
  &[data-tn-seat-id="527146"] { transform: matrix(1, 0, 0, 1, -212.90283203125, 676.601806640625); }
  &[data-tn-seat-id="527147"] { transform: matrix(1, 0, 0, 1, -310.043701171875, 614.7735595703125); }
  &[data-tn-seat-id="527148"] { transform: matrix(1, 0, 0, 1, -402.7196044921875, 551.3157958984375); }
  &[data-tn-seat-id="527149"] { transform: matrix(1, 0, 0, 1, -490.0416259765625, 486.3651123046875); }


  &[data-tn-seat-id="527152"] { transform: matrix(1, 0, 0, 1, -698.5467529296875, 286.77392578125); }
  &[data-tn-seat-id="527153"] { transform: matrix(1, 0, 0, 1, -761.0029296875, 226.0782470703125); }
  &[data-tn-seat-id="527154"] { transform: matrix(1, 0, 0, 1, -817.670654296875, 166.56683349609375); }
  &[data-tn-seat-id="527155"] { transform: matrix(1, 0, 0, 1, -869.028076171875, 108.68475341796875); }
  &[data-tn-seat-id="527156"] { transform: matrix(1, 0, 0, 1, -914.8248291015625, 53.10406494140625); }
  &[data-tn-seat-id="527157"] { transform: matrix(1, 0, 0, 1, -954.681396484375, 0.74298095703125); }
  &[data-tn-seat-id="527158"] { transform: matrix(1, 0, 0, 1, -988.3057861328125, -47.22607421875); }

  // Row H
  &[data-tn-seat-id="527066"] { transform: matrix(1, 0, 0, 1, 179.082763671875, 1007.069580078125); }
  &[data-tn-seat-id="527067"] { transform: matrix(1, 0, 0, 1, 65.8236083984375, 960.887451171875); }
  &[data-tn-seat-id="527068"] { transform: matrix(1, 0, 0, 1, -42.7186279296875, 910.866455078125); }
  &[data-tn-seat-id="527069"] { transform: matrix(1, 0, 0, 1, -146.8759765625, 857.918212890625); }
  &[data-tn-seat-id="527070"] { transform: matrix(1, 0, 0, 1, -246.4212646484375, 802.431640625); }
  &[data-tn-seat-id="527071"] { transform: matrix(1, 0, 0, 1, -341.0006103515625, 744.747314453125); }
  &[data-tn-seat-id="527072"] { transform: matrix(1, 0, 0, 1, -430.23077392578125, 685.2474365234375); }
  &[data-tn-seat-id="527073"] { transform: matrix(1, 0, 0, 1, -513.77777099609375, 624.39013671875); }
  &[data-tn-seat-id="527074"] { transform: matrix(1, 0, 0, 1, -591.41009521484375, 562.704345703125); }

  &[data-tn-seat-id="527077"] { transform: matrix(1, 0, 0, 1, -813.8561401367188, 360.86956787109375); }
  &[data-tn-seat-id="527078"] { transform: matrix(1, 0, 0, 1, -876.0245361328125, 301.15618896484375); }
  &[data-tn-seat-id="527079"] { transform: matrix(1, 0, 0, 1, -934.0515747070312, 242.2650146484375); }
  &[data-tn-seat-id="527080"] { transform: matrix(1, 0, 0, 1, -988.47314453125, 184.34759521484375); }
  &[data-tn-seat-id="527081"] { transform: matrix(1, 0, 0, 1, -1039.2388305664062, 127.669677734375); }
  &[data-tn-seat-id="527082"] { transform: matrix(1, 0, 0, 1, -1086.1553955078125, 72.58941650390625); }
  &[data-tn-seat-id="527083"] { transform: matrix(1, 0, 0, 1, -1128.9599609375, 19.57769775390625); }
  &[data-tn-seat-id="527084"] { transform: matrix(1, 0, 0, 1, -1167.373779296875, -30.76226806640625); }
  &[data-tn-seat-id="527085"] { transform: matrix(1, 0, 0, 1, -1201.1690673828125, -77.68695068359375); }

  // Row J
  &[data-tn-seat-id="526990"] { transform: matrix(1, 0, 0, 1, 161.8992919921875, 1159.5826416015625); }
  &[data-tn-seat-id="526991"] { transform: matrix(1, 0, 0, 1, 50.051513671875, 1109.2327880859375); }
  &[data-tn-seat-id="526992"] { transform: matrix(1, 0, 0, 1, -58.093017578125, 1056.1480712890625); }
  &[data-tn-seat-id="526993"] { transform: matrix(1, 0, 0, 1, -162.95819091796875, 1000.9716796875); }
  &[data-tn-seat-id="526994"] { transform: matrix(1, 0, 0, 1, -264.46990966796875, 943.931396484375); }
  &[data-tn-seat-id="526995"] { transform: matrix(1, 0, 0, 1, -362.4197998046875, 885.1859130859375); }
  &[data-tn-seat-id="526996"] { transform: matrix(1, 0, 0, 1, -456.52899169921875, 824.8912353515625); }
  &[data-tn-seat-id="526997"] { transform: matrix(1, 0, 0, 1, -546.4765625, 763.231201171875); }
  &[data-tn-seat-id="526998"] { transform: matrix(1, 0, 0, 1, -631.9178466796875, 700.436279296875); }
  &[data-tn-seat-id="526999"] { transform: matrix(1, 0, 0, 1, -712.5252075195312, 636.800048828125); }

  &[data-tn-seat-id="527002"] { transform: matrix(1, 0, 0, 1, -924.9712524414062, 430.77392578125); }
  &[data-tn-seat-id="527003"] { transform: matrix(1, 0, 0, 1, -988.12255859375, 367.76202392578125); }
  &[data-tn-seat-id="527004"] { transform: matrix(1, 0, 0, 1, -1047.3001098632812, 305.42913818359375); }
  &[data-tn-seat-id="527005"] { transform: matrix(1, 0, 0, 1, -1103.0303344726562, 243.90582275390625); }
  &[data-tn-seat-id="527006"] { transform: matrix(1, 0, 0, 1, -1155.269775390625, 183.41424560546875); }
  &[data-tn-seat-id="527007"] { transform: matrix(1, 0, 0, 1, -1203.82421875, 124.25518798828125); }
  &[data-tn-seat-id="527008"] { transform: matrix(1, 0, 0, 1, -1248.4154663085938, 66.82781982421875); }
  &[data-tn-seat-id="527009"] { transform: matrix(1, 0, 0, 1, -1288.7208251953125, 11.654266357421875); }
  &[data-tn-seat-id="527010"] { transform: matrix(1, 0, 0, 1, -1324.4210205078125, -40.598968505859375); }
  &[data-tn-seat-id="527011"] { transform: matrix(1, 0, 0, 1, -1355.25537109375, -89.10433959960938); }

  // Row K
  &[data-tn-seat-id="526914"] { transform: matrix(1, 0, 0, 1, 141.81292724609375, 1298.382568359375); }
  &[data-tn-seat-id="526915"] { transform: matrix(1, 0, 0, 1, 28.14373779296875, 1248.5611572265625); }
  &[data-tn-seat-id="526916"] { transform: matrix(1, 0, 0, 1, -81.74432373046875, 1195.7911376953125); }
  &[data-tn-seat-id="526917"] { transform: matrix(1, 0, 0, 1, -188.33697509765625, 1140.8028564453125); }
  &[data-tn-seat-id="526918"] { transform: matrix(1, 0, 0, 1, -291.6083984375, 1083.860107421875); }
  &[data-tn-seat-id="526919"] { transform: matrix(1, 0, 0, 1, -391.41534423828125, 1025.1480712890625); }
  &[data-tn-seat-id="526920"] { transform: matrix(1, 0, 0, 1, -487.5511474609375, 964.834716796875); }
  &[data-tn-seat-id="526921"] { transform: matrix(1, 0, 0, 1, -579.7828369140625, 903.10302734375); }
  &[data-tn-seat-id="526922"] { transform: matrix(1, 0, 0, 1, -667.8690185546875, 840.16455078125); }
  &[data-tn-seat-id="526923"] { transform: matrix(1, 0, 0, 1, -751.5862426757812, 776.2694091796875); }
  &[data-tn-seat-id="526924"] { transform: matrix(1, 0, 0, 1, -830.7374267578125, 711.704345703125); }

  &[data-tn-seat-id="526927"] { transform: matrix(1, 0, 0, 1, -1043.1834716796875, 504.86956787109375); }
  &[data-tn-seat-id="526928"] { transform: matrix(1, 0, 0, 1, -1107.8431396484375, 445.89605712890625); }
  &[data-tn-seat-id="526929"] { transform: matrix(1, 0, 0, 1, -1169.1062622070312, 387.43865966796875); }
  &[data-tn-seat-id="526930"] { transform: matrix(1, 0, 0, 1, -1227.4794921875, 329.58624267578125); }
  &[data-tn-seat-id="526931"] { transform: matrix(1, 0, 0, 1, -1282.9724731445312, 272.48931884765625); }
  &[data-tn-seat-id="526932"] { transform: matrix(1, 0, 0, 1, -1335.4703369140625, 216.34576416015625); }
  &[data-tn-seat-id="526933"] { transform: matrix(1, 0, 0, 1, -1384.785888671875, 161.4124755859375); }
  &[data-tn-seat-id="526934"] { transform: matrix(1, 0, 0, 1, -1430.6893920898438, 108.017822265625); }
  &[data-tn-seat-id="526935"] { transform: matrix(1, 0, 0, 1, -1472.9266357421875, 56.579742431640625); }
  &[data-tn-seat-id="526936"] { transform: matrix(1, 0, 0, 1, -1511.2531127929688, 7.616363525390625); }
  &[data-tn-seat-id="526937"] { transform: matrix(1, 0, 0, 1, -1545.4676513671875, -38.24346923828125); }


  // Row L
  &[data-tn-seat-id="526839"] { transform: matrix(1, 0, 0, 1, 16.98919677734375, 1403.13916015625); }
  &[data-tn-seat-id="526840"] { transform: matrix(1, 0, 0, 1, -92.6673583984375, 1347.302001953125); }
  &[data-tn-seat-id="526841"] { transform: matrix(1, 0, 0, 1, -199.14501953125, 1289.438720703125); }
  &[data-tn-seat-id="526842"] { transform: matrix(1, 0, 0, 1, -302.90789794921875, 1230.0408935546875); }
  &[data-tn-seat-id="526843"] { transform: matrix(1, 0, 0, 1, -403.97503662109375, 1169.278564453125); }
  &[data-tn-seat-id="526844"] { transform: matrix(1, 0, 0, 1, -502.2362060546875, 1107.2520751953125); }
  &[data-tn-seat-id="526845"] { transform: matrix(1, 0, 0, 1, -597.5202026367188, 1044.04833984375); }
  &[data-tn-seat-id="526846"] { transform: matrix(1, 0, 0, 1, -689.6065673828125, 979.7578125); }
  &[data-tn-seat-id="526847"] { transform: matrix(1, 0, 0, 1, -778.2344360351562, 914.4881591796875); }
  &[data-tn-seat-id="526848"] { transform: matrix(1, 0, 0, 1, -863.1175537109375, 848.378662109375); }
  &[data-tn-seat-id="526849"] { transform: matrix(1, 0, 0, 1, -943.9496459960938, 781.608642578125); }

  &[data-tn-seat-id="526852"] { transform: matrix(1, 0, 0, 1, -1153.4927978515625, 579.77392578125); }
  &[data-tn-seat-id="526853"] { transform: matrix(1, 0, 0, 1, -1219.37451171875, 517.3707885742188); }
  &[data-tn-seat-id="526854"] { transform: matrix(1, 0, 0, 1, -1281.6694946289062, 455.4166259765625); }
  &[data-tn-seat-id="526855"] { transform: matrix(1, 0, 0, 1, -1340.9730834960938, 394.0050048828125); }
  &[data-tn-seat-id="526856"] { transform: matrix(1, 0, 0, 1, -1397.3472900390625, 333.2728271484375); }
  &[data-tn-seat-id="526857"] { transform: matrix(1, 0, 0, 1, -1450.7297973632812, 273.390625); }
  &[data-tn-seat-id="526858"] { transform: matrix(1, 0, 0, 1, -1500.99169921875, 214.5704345703125); }
  &[data-tn-seat-id="526859"] { transform: matrix(1, 0, 0, 1, -1547.9690551757812, 157.07199096679688); }
  &[data-tn-seat-id="526860"] { transform: matrix(1, 0, 0, 1, -1591.4775390625, 101.21295166015625); }
  &[data-tn-seat-id="526861"] { transform: matrix(1, 0, 0, 1, -1631.3372802734375, 47.372833251953125); }
  &[data-tn-seat-id="526862"] { transform: matrix(1, 0, 0, 1, -1667.3884887695312, -4); }

  // Row M
  &[data-tn-seat-id="526764"] { transform: matrix(1, 0, 0, 1, -132.6690673828125, 1451.904296875); }
  &[data-tn-seat-id="526765"] { transform: matrix(1, 0, 0, 1, -238.13641357421875, 1397.5865478515625); }
  &[data-tn-seat-id="526766"] { transform: matrix(1, 0, 0, 1, -340.66595458984375, 1341.5577392578125); }
  &[data-tn-seat-id="526767"] { transform: matrix(1, 0, 0, 1, -440.61822509765625, 1284.19921875); }
  &[data-tn-seat-id="526768"] { transform: matrix(1, 0, 0, 1, -537.9319458007812, 1225.6285400390625); }
  &[data-tn-seat-id="526769"] { transform: matrix(1, 0, 0, 1, -632.4180908203125, 1165.916259765625); }
  &[data-tn-seat-id="526770"] { transform: matrix(1, 0, 0, 1, -723.8009033203125, 1105.1268310546875); }
  &[data-tn-seat-id="526771"] { transform: matrix(1, 0, 0, 1, -811.7230224609375, 1043.3424072265625); }
  &[data-tn-seat-id="526772"] { transform: matrix(1, 0, 0, 1, -895.75341796875, 980.6861572265625); }
  &[data-tn-seat-id="526773"] { transform: matrix(1, 0, 0, 1, -975.3873291015625, 917.3466796875); }
  &[data-tn-seat-id="526774"] { transform: matrix(1, 0, 0, 1, -1050.0647583007812, 853.608642578125); }

  &[data-tn-seat-id="526777"] { transform: matrix(1, 0, 0, 1, -1269.60791015625, 646.77392578125); }
  &[data-tn-seat-id="526778"] { transform: matrix(1, 0, 0, 1, -1336.1348266601562, 585.7018432617188); }
  &[data-tn-seat-id="526779"] { transform: matrix(1, 0, 0, 1, -1399.2308959960938, 525.02783203125); }
  &[data-tn-seat-id="526780"] { transform: matrix(1, 0, 0, 1, -1459.4811401367188, 464.8394775390625); }
  &[data-tn-seat-id="526781"] { transform: matrix(1, 0, 0, 1, -1516.9595947265625, 405.2591552734375); }
  &[data-tn-seat-id="526782"] { transform: matrix(1, 0, 0, 1, -1571.6212158203125, 346.4373779296875); }
  &[data-tn-seat-id="526783"] { transform: matrix(1, 0, 0, 1, -1623.3587036132812, 288.55853271484375); }
  &[data-tn-seat-id="526784"] { transform: matrix(1, 0, 0, 1, -1672.0288696289062, 231.84768676757812); }
  &[data-tn-seat-id="526785"] { transform: matrix(1, 0, 0, 1, -1717.4688720703125, 176.57736206054688); }
  &[data-tn-seat-id="526786"] { transform: matrix(1, 0, 0, 1, -1759.5152587890625, 123.07208251953125); }
  &[data-tn-seat-id="526787"] { transform: matrix(1, 0, 0, 1, -1798.0180053710938, 71.71304321289062); }

  // Row N
  &[data-tn-seat-id="526692"] { transform: matrix(1, 0, 0, 1, -564.7733764648438, 1347.069580078125); }
  &[data-tn-seat-id="526693"] { transform: matrix(1, 0, 0, 1, -659.171630859375, 1289.6531982421875); }
  &[data-tn-seat-id="526694"] { transform: matrix(1, 0, 0, 1, -750.430419921875, 1231.1015625); }
  &[data-tn-seat-id="526695"] { transform: matrix(1, 0, 0, 1, -839.0751342773438, 1171.7530517578125); }
  &[data-tn-seat-id="526696"] { transform: matrix(1, 0, 0, 1, -925.1760864257812, 1111.749267578125); }
  &[data-tn-seat-id="526697"] { transform: matrix(1, 0, 0, 1, -1008.6867370605469, 1051.1954345703125); }
  &[data-tn-seat-id="526698"] { transform: matrix(1, 0, 0, 1, -1089.4976501464844, 990.190673828125); }
  &[data-tn-seat-id="526699"] { transform: matrix(1, 0, 0, 1, -1167.4712219238281, 928.843505859375); }

  &[data-tn-seat-id="526702"] { transform: matrix(1, 0, 0, 1, -1385.7230224609375, 717.252197265625); }
  &[data-tn-seat-id="526703"] { transform: matrix(1, 0, 0, 1, -1451.1326904296875, 653.8515014648438); }
  &[data-tn-seat-id="526704"] { transform: matrix(1, 0, 0, 1, -1513.498291015625, 590.8372192382812); }
  &[data-tn-seat-id="526705"] { transform: matrix(1, 0, 0, 1, -1573.1395874023438, 528.3004150390625); }
  &[data-tn-seat-id="526706"] { transform: matrix(1, 0, 0, 1, -1629.9242248535156, 466.39752197265625); }
  &[data-tn-seat-id="526707"] { transform: matrix(1, 0, 0, 1, -1683.5512390136719, 405.35931396484375); }
  &[data-tn-seat-id="526708"] { transform: matrix(1, 0, 0, 1, -1733.5778198242188, 345.526123046875); }
  &[data-tn-seat-id="526709"] { transform: matrix(1, 0, 0, 1, -1779.4136657714844, 287.40869140625); }

  // Row P
  &[data-tn-seat-id="526617"] { transform: matrix(1, 0, 0, 1, -680.8884887695312, 1451.49560546875); }
  &[data-tn-seat-id="526618"] { transform: matrix(1, 0, 0, 1, -773.9483947753906, 1388.8541259765625); }
  &[data-tn-seat-id="526619"] { transform: matrix(1, 0, 0, 1, -864.7574768066406, 1325.4974365234375); }
  &[data-tn-seat-id="526620"] { transform: matrix(1, 0, 0, 1, -953.5934143066406, 1261.588134765625); }
  &[data-tn-seat-id="526621"] { transform: matrix(1, 0, 0, 1, -1040.4024658203125, 1197.1790771484375); }
  &[data-tn-seat-id="526622"] { transform: matrix(1, 0, 0, 1, -1125.0136108398438, 1132.3046875); }
  &[data-tn-seat-id="526623"] { transform: matrix(1, 0, 0, 1, -1207.1643676757812, 1067.0009765625); }
  &[data-tn-seat-id="526624"] { transform: matrix(1, 0, 0, 1, -1286.4891967773438, 1001.32177734375); }

  &[data-tn-seat-id="526627"] { transform: matrix(1, 0, 0, 1, -1498.1294860839844, 787.5391235351562); }
  &[data-tn-seat-id="526628"] { transform: matrix(1, 0, 0, 1, -1565.6019287109375, 724.5944213867188); }
  &[data-tn-seat-id="526629"] { transform: matrix(1, 0, 0, 1, -1629.954345703125, 661.9470825195312); }
  &[data-tn-seat-id="526630"] { transform: matrix(1, 0, 0, 1, -1691.6210327148438, 600.6860961914062); }
  &[data-tn-seat-id="526631"] { transform: matrix(1, 0, 0, 1, -1750.5822143554688, 537.9354858398438); }
  &[data-tn-seat-id="526632"] { transform: matrix(1, 0, 0, 1, -1806.6790161132812, 476.86077880859375); }
  &[data-tn-seat-id="526633"] { transform: matrix(1, 0, 0, 1, -1859.6696166992188, 416.681884765625); }
  &[data-tn-seat-id="526634"] { transform: matrix(1, 0, 0, 1, -1909.2373962402344, 357.6956481933594); }

  // Row Q

  // This row is ordered backwards
  &[data-tn-seat-id="526558"] { transform: matrix(1, 0, 0, 1, -1977, 486); }
  &[data-tn-seat-id="526557"] { transform: matrix(1, 0, 0, 1, -1917.75, 549.25); }
  &[data-tn-seat-id="526556"] { transform: matrix(1, 0, 0, 1, -1858.5, 612.5); }
  &[data-tn-seat-id="526555"] { transform: matrix(1, 0, 0, 1, -1799.25, 675.75); }
  &[data-tn-seat-id="526554"] { transform: matrix(1, 0, 0, 1, -1740, 739); }
  &[data-tn-seat-id="527236"] { transform: matrix(1, 0, 0, 1, -1677, 804) }
  &[data-tn-seat-id="527235"] { transform: matrix(1, 0, 0, 1, -1615, 867) }
  &[data-tn-seat-id="526567"] { transform: matrix(1, 0, 0, 1, -1548, 932) }

  &[data-tn-seat-id="526566"] { transform: matrix(1, 0, 0, 1, -1477.0020751953125, 998.889404296875); }
  &[data-tn-seat-id="527165"] { transform: matrix(1, 0, 0, 1, -1398.8621063232422, 1063.9210205078125); }
  &[data-tn-seat-id="527164"] { transform: matrix(1, 0, 0, 1, -1318.9627532958984, 1128.791015625); }
  &[data-tn-seat-id="526547"] { transform: matrix(1, 0, 0, 1, -1237.4858856201172, 1193.4737548828125); }
  &[data-tn-seat-id="526546"] { transform: matrix(1, 0, 0, 1, -1154.3354797363281, 1257.9146728515625); }
  &[data-tn-seat-id="526545"] { transform: matrix(1, 0, 0, 1, -1068.3082427978516, 1322.03125); }
  &[data-tn-seat-id="526544"] { transform: matrix(1, 0, 0, 1, -985.0834503173828, 1385.695068359375); }
  &[data-tn-seat-id="526543"] { transform: matrix(1, 0, 0, 1, -898.1808776855469, 1448.698486328125); }

  // Row C
  &[data-tn-seat-id="527616"] { transform: matrix(1, 0, 0, 1, -1347.79833984375, -246.482421875); }
  &[data-tn-seat-id="527691"] { transform: matrix(1, 0, 0, 1, -1287.6202392578125, -348.3955993652344); }
  &[data-tn-seat-id="527766"] { transform: matrix(1, 0, 0, 1, -1223.64453125, -440.83416748046875); }

  &[data-tn-seat-id="527991"] { transform: matrix(1, 0, 0, 1, -1100, -670); }
  &[data-tn-seat-id="528081"] { transform: matrix(1, 0, 0, 1, -1377.2687988281, -1132.121017); }
  &[data-tn-seat-id="528066"] { transform: matrix(1, 0, 0, 1, -1035, -739); }
  &[data-tn-seat-id="528141"] { transform: matrix(1, 0, 0, 1, -972, -800); }
  &[data-tn-seat-id="528216"] { transform: matrix(1, 0, 0, 1, -913, -852); }
  &[data-tn-seat-id="527550"] { transform: matrix(1, 0, 0, 1, -850, -899); }
  &[data-tn-seat-id="528300"] { transform: matrix(1, 0, 0, 1, -795, -943); }
  &[data-tn-seat-id="528375"] { transform: matrix(1, 0, 0, 1, -743, -976); }

  // Row D
  &[data-tn-seat-id="527918"] { transform: matrix(1, 0, 0, 1, -1273.6236572265625, -704.1055297851562); }
  &[data-tn-seat-id="527993"] { transform: matrix(1, 0, 0, 1, -1207.865234375, -780.1785278320312); }
  &[data-tn-seat-id="528068"] { transform: matrix(1, 0, 0, 1, -1142.03515625, -849.275390625); }
  &[data-tn-seat-id="528143"] { transform: matrix(1, 0, 0, 1, -1076.94287109375, -911.0962829589844); }
  &[data-tn-seat-id="528218"] { transform: matrix(1, 0, 0, 1, -1013.8314208984375, -964.2639770507812); }
  &[data-tn-seat-id="528293"] { transform: matrix(1, 0, 0, 1, -954.850341796875, -1007.0401916503906); }
  &[data-tn-seat-id="527702"] { transform: matrix(1, 0, 0, 1, -896.162109375, -1045.542724609375); }
  &[data-tn-seat-id="528377"] { transform: matrix(1, 0, 0, 1, -845.309326171875, -1081.3258666992188); }
  &[data-tn-seat-id="528452"] { transform: matrix(1, 0, 0, 1, -795.496826171875, -1115.6934814453125); }

  &[data-tn-seat-id="527477"] { transform: matrix(1, 0, 0, 1, -1569.0416259765625, -260.1155700683594); }
  &[data-tn-seat-id="527552"] { transform: matrix(1, 0, 0, 1, -1510.767822265625, -361.5726623535156); }
  &[data-tn-seat-id="527627"] { transform: matrix(1, 0, 0, 1, -1451.5987548828125, -461.180908203125); }

  // Row E
  &[data-tn-seat-id="527704"] { transform: matrix(1, 0, 0, 1, -1179.382568359375, -1020.8090515136719); }
  &[data-tn-seat-id="528154"] { transform: matrix(1, 0, 0, 1, -1116.581298828125, -1082.6798095703125); }
  &[data-tn-seat-id="528229"] { transform: matrix(1, 0, 0, 1, -1055.228759765625, -1137.9547729492188); }

  &[data-tn-seat-id="527995"] { transform: matrix(1, 0, 0, 1, -1380.3486328125, -900.6036529541016); }
  &[data-tn-seat-id="527920"] { transform: matrix(1, 0, 0, 1, -1442.5849609375, -820.5796203613281); }
  &[data-tn-seat-id="527479"] { transform: matrix(1, 0, 0, 1, -1786.7962646484375, -340.67840576171875); }
  &[data-tn-seat-id="527554"] { transform: matrix(1, 0, 0, 1, -1728.1353759765625, -447.9515075683594); }

  &[data-tn-seat-id="527629"] { transform: matrix(1, 0, 0, 1, -1664.65380859375, -545.8306884765625); }
  &[data-tn-seat-id="527770"] { transform: matrix(1, 0, 0, 1, -1598.8876953125, -637.2412109375); }

  // Row F
  &[data-tn-seat-id="528306"] { transform: matrix(1, 0, 0, 1, -1195.4545898438, -1311.828018) }
  &[data-tn-seat-id="528231"] { transform: matrix(1, 0, 0, 1, -1255.5090332031, -1252.158646)}
  &[data-tn-seat-id="528156"] { transform: matrix(1, 0, 0, 1, -1316.0865478516, -1192.266197) }
  &[data-tn-seat-id="528081"] { transform: matrix(1, 0, 0, 1, -1377.2687988281, -1132.121017) }

  &[data-tn-seat-id="527922"] { transform: matrix(1, 0, 0, 1, -1588.939331, -917.557816) }
  &[data-tn-seat-id="527997"] { transform: matrix(1, 0, 0, 1, -1527.345215, -994.686951) }

}

.tn-syos-seat-map:has([data-tn-seat-id="528651"]),
.tn-syos-seat-map:has([data-tn-seat-id="526995"]) {
    &::after {
        content: '';
        position: absolute;
        inset: 0;
        height: 100%;
        width: 100%;
        overflow: visible;
        pointer-events: none;
        background-repeat: no-repeat;
        z-index: 10;
        opacity: 1;
        background-image: url("../img/circle-stage.svg");
        mix-blend-mode: multiply;
        background-size: 22%;
        background-position: 52% 50%;
    }
    .tn-syos-seat-map__non-seat {
        visibility: hidden;
    }
}
